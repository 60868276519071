import { Euler } from 'three'

/**
 *
 * @param degrees input number representing the degrees
 * @returns { number } output euler angle as a number
 */
const degreesToEulerAngle = (degrees = 0): number => {
  return degrees * (Math.PI / 180)
}

/**
 * Returns an Euler of a given 3D rotation in degrees
 * @param x Rotation degrees on the X axis
 * @param y Rotation degrees on the Y axis
 * @param z Rotation degrees on the Z axis
 * @returns { Euler } Output rotation as an Euler
 */
export const rotationDegreesToEuler = (x = 0, y = 0, z = 0): Euler => {
  const xEuler = degreesToEulerAngle(x)
  const yEuler = degreesToEulerAngle(y)
  const zEuler = degreesToEulerAngle(z)

  return new Euler(xEuler, yEuler, zEuler)
}
