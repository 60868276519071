import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { Fragment, useState } from 'react'
import { Checkbox } from '../Checkbox/Checkbox'

const Value = ({
  label,
  checked,
  setChecked,
}: {
  label: string
  checked: boolean
  setChecked: (checked: boolean) => void
}) => {
  return (
    <ListItemButton
      sx={{ pl: 4 }}
      onClick={() => {
        setChecked(!checked)
      }}
    >
      <IconButton disabled>
        <ExpandMore sx={{ visibility: 'hidden' }} />
      </IconButton>
      <ListItemIcon>
        <Checkbox
          onChange={(e) => {
            e.stopPropagation()
            setChecked(!checked)
          }}
          checked={checked}
        />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  )
}

const Item = <T extends string | number, V extends string | number>({
  layout,
  value,
  onChange,
}: {
  value: CheckboxDropdownOptionState<V>
  onChange: (value: CheckboxDropdownOptionState<V>) => void
  layout: CheckboxCategory<T, V>
}) => {
  const [open, setOpen] = useState(false)

  const checkedValues = Object.values(value).map((value) => value)
  const allChecked =
    checkedValues.length === layout.options.length &&
    checkedValues.every((value) => value)
  const someChecked = Object.values(value).some((value) => value)

  const toggleAll = () => {
    if (allChecked) onChange({})
    else
      onChange(
        Object.assign(
          {},
          ...layout.options.map((value) => ({ [value.value]: true })),
        ),
      )
  }

  const ParentContents = () => (
    <>
      <ListItemIcon>
        <Checkbox
          checked={someChecked}
          indeterminate={someChecked && !allChecked}
          onClick={(e) => {
            e.stopPropagation()
            toggleAll()
          }}
        />
      </ListItemIcon>
      <ListItemText>{layout.label}</ListItemText>
    </>
  )
  return (
    <>
      <Box component="div">
        {layout.options.length === 0 ? (
          <ListItem onClick={toggleAll}>
            <ParentContents />
          </ListItem>
        ) : (
          <ListItemButton onClick={toggleAll}>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                setOpen(!open)
              }}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            <ParentContents />
          </ListItemButton>
        )}

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{
              '& .MuiListItemButton-root': {
                paddingLeft: '5px !important',
              },
            }}
          >
            {layout.options.map((option) => {
              return (
                <Value
                  key={`${layout.value}-${option.label}-${option.value}`}
                  label={option.label}
                  checked={value[option.value] ?? false}
                  setChecked={(checkedValue) =>
                    onChange({ ...value, [option.value]: checkedValue })
                  }
                />
              )
            })}
          </List>
        </Collapse>
      </Box>
    </>
  )
}

export interface CheckboxCategory<T, V> {
  label: string
  value: T
  options: CheckboxOption<V>[]
}

export interface CheckboxOption<T> {
  label: string
  value: T
}

export type CheckboxDropdownOptionState<V extends string | number> = {
  [P in V]?: boolean
}

export type CheckboxDropdownState<
  T extends string | number,
  V extends string | number,
> = {
  [P in T]?: CheckboxDropdownOptionState<V>
}

export const CheckboxDropdown = <
  T extends string | number,
  V extends string | number,
>({
  options,
  error,
  value,
  onChange,
}: {
  options: { [id in T]: CheckboxCategory<T, V> }
  error?: boolean
  value: CheckboxDropdownState<T, V>
  onChange: (category: T, value: CheckboxDropdownOptionState<V>) => void
}) => {
  const columns: T[][] = [[], []]
  const parents = Object.keys(options) as T[]
  for (let i = 0; i < parents.length; i++) {
    if (i % 2 === 0) columns[0].push(parents[i])
    else columns[1].push(parents[i])
  }

  return (
    <List
      sx={{
        width: '100%',
        display: 'flex',
        border: (theme) =>
          `1px solid ${error ? theme.palette.error.main : theme.outline.main}`,
        borderRadius: '4px',
        gap: '8px',
        paddingTop: '2px',
        paddingBottom: '2px',
        flex: '0 0 50%',
        '& .MuiListItemIcon-root': {
          minWidth: 'unset',
        },
        '& .MuiListItemButton-root': {
          padding: '0px',
        },
      }}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          marginBottom: '1em',
          width: '50%',
        }}
      >
        {columns[0].map((category, index) => {
          return (
            <Fragment key={category}>
              <Item
                layout={options[category]}
                value={value?.[category] ?? {}}
                onChange={(value) => onChange(category, value)}
              />
              {index !== columns[0].length - 1 ? (
                <Divider sx={{ marginLeft: '4px', marginRight: '4px' }} />
              ) : (
                <></>
              )}
            </Fragment>
          )
        })}
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          marginBottom: '1em',
          width: '50%',
        }}
      >
        {columns[1].map((category, index) => {
          return (
            <Fragment key={category}>
              <Item
                layout={options[category]}
                value={value?.[category] ?? {}}
                onChange={(value) => onChange(category, value)}
              />
              {index !== columns[1].length - 1 ? (
                <Divider sx={{ marginLeft: '4px', marginRight: '4px' }} />
              ) : (
                <></>
              )}
            </Fragment>
          )
        })}
      </Box>
    </List>
  )
}
