import { ModelFileTypes } from '../typed/ModelFileTypes'

/**
 * Checks if a given ArrayBuffer is an STL or a PLY file.
 * @param fileData
 * @returns { string | undefined } "ply", "stl" or undefined
 */
export const getModelFileTypeFromData = (
  fileData: ArrayBuffer,
): ModelFileTypes | undefined => {
  const bytes = new Uint8Array(fileData)

  // Ensure the file has at least 4 bytes to check for PLY signature "ply"
  if (fileData.byteLength >= 4) {
    const text = new TextDecoder().decode(fileData.slice(0, 4))
    if (text === 'ply') {
      return 'ply'
    }
  }

  // Check for ASCII STL (starts with 'solid')
  const asciiSTLHeader = 'solid'.split('').map((c) => c.charCodeAt(0))
  if (bytes.slice(0, 5).every((byte, idx) => byte === asciiSTLHeader[idx])) {
    return 'stl'
  }

  // Check for Binary STL (first 80 bytes are the header, which may contain non-zero values)
  if (bytes.length > 80) {
    // Typically, a valid binary STL has a valid number of triangles after the 80-byte header
    // Each triangle is 50 bytes: 12 bytes for normal, 3x3=9 bytes for vertices, and 2 bytes for the attribute byte count
    const numTriangles = (bytes.length - 80) / 50
    if (numTriangles > 0 && (bytes.length - 80) % 50 === 0) {
      return 'stl'
    }
  }

  return undefined
}

/**
 * Takes an S3 URL and returns if the file is a ply or stl inferred by it's extension
 * @param url the S3 part URL
 * @returns { ModelFileTypes | undefined } ModelFileTypes or undefined
 */
export const getModelFileTypeFromS3URL = (
  url: string | undefined,
): ModelFileTypes | undefined => {
  if (url === undefined) {
    return undefined
  }
  // Extract the file extension from the URL
  const fileExtension = url.split('.').pop()?.split('?')[0]

  // Check if the file extension is "stl" or "ply"
  if (fileExtension === 'stl') {
    return 'stl'
  } else if (fileExtension === 'ply') {
    return 'ply'
  }

  // If it's neither, return undefined
  return undefined
}
