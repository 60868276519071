import { Box, Divider, useMediaQuery, useTheme } from '@mui/material'
import * as Sentry from '@sentry/react'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Viewport } from '../'
import { partClassification } from '../../constants/partClassification'
import {
  PartClassification,
  PartStatus,
  useGetPartApiV1PartsPartIdGetQuery,
} from '../../store/clientApi'
import { setDrawerOpen } from '../../store/slices/rightDrawerSlice'
import {
  selectDetailsItem,
  selectNumChecked,
} from '../../store/slices/tableSlice'
import { availableViewportModel } from '../../store/slices/viewportModelSlice'
import { RootState, store } from '../../store/store'
import { ModelFileTypes } from '../../typed/ModelFileTypes'
import { rotationDegreesToEuler } from '../../utils/geometryUtils'
import { getModelFromIndexedDB } from '../../utils/indexeddb'
import { partStatusToTranslatedKey } from '../../utils/partStatusToTranslatedKey'
import {
  DetailsGrid,
  ExtendedDetails,
} from '../ExtendedDetails/ExtendedDetails'
import { Model } from '../Model/Model'
import { PartButtonGroup, PartButtonGroupType } from '../PartActionBar/buttons'
import { PART_TABLE_STATE_NAMES } from '../PartsTable/PartsTable'
import { RightDrawer } from '../RightDrawer/RightDrawer'
import { ViewportContainer, ViewportLoading } from '../Viewport/Viewport'

interface InboxInspectorProps {
  table: PART_TABLE_STATE_NAMES
  drawer: PART_INSPECTOR_STATES
  currentTab: string | false
}

const PartViewport = ({
  actionBarButtons,
  selectNumSelected,
  selectPartId,
}: {
  selectNumSelected: (state: RootState) => number
  selectPartId: (state: RootState) => string | undefined
  actionBarButtons: ReactNode
}) => {
  const [modelFile, setModelFile] = useState<
    | {
        modelId: string
        modelData: {
          data: ArrayBuffer
          type: ModelFileTypes
        }
      }
    | undefined
  >()
  const { modelViewportError } = useSelector(
    (state: RootState) => state.viewportModel,
  )

  const numSelected = useSelector(
    (state: RootState) => selectNumSelected(state) < 2,
  )

  const selectedPartId = useSelector(selectPartId)

  const { partName, selectedPartStatus } = useGetPartApiV1PartsPartIdGetQuery(
    {
      partId: Number(selectedPartId),
    },
    {
      selectFromResult: ({ data }) => {
        return {
          partName: data?.name,
          selectedPartStatus: data?.status,
        }
      },
      skip: selectedPartId === undefined,
    },
  )

  const isSelected = useSelector(
    (state: RootState) => selectNumSelected(state) > 0,
  )

  useEffect(() => {
    if (selectedPartId === undefined || selectedPartStatus === undefined) {
      return
    }
    if (modelFile && selectedPartId !== modelFile.modelId) {
      setModelFile(undefined)
    }

    const loadModelFile = async () => {
      const modelAvailable = await store.dispatch(
        availableViewportModel({
          partId: parseInt(selectedPartId),
          partStatus: selectedPartStatus,
        }),
      )
      if (modelAvailable) {
        const modelFileData = await getModelFromIndexedDB(
          selectedPartId,
          'PART',
        )
        if (modelFileData !== null) {
          setModelFile({ modelId: selectedPartId, modelData: modelFileData })
        }
      }
    }
    loadModelFile()
  }, [selectedPartId, selectedPartStatus]) // eslint-disable-line react-hooks/exhaustive-deps

  if (selectedPartId && numSelected) {
    if (partName && modelFile) {
      return (
        <Sentry.ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag('location', 'part inspector model viewer')
          }}
          fallback={
            <>
              <ViewportContainer>
                <img
                  alt=""
                  src="/images/inspectorNoParts.svg"
                  style={{
                    padding: '1em',
                    boxSizing: 'border-box',
                    height: '100%',
                  }}
                />
              </ViewportContainer>
            </>
          }
        >
          <Viewport
            modelName={partName}
            maximisedFooter={
              selectedPartId && (
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    gap: '6px',
                  }}
                >
                  {actionBarButtons}
                </Box>
              )
            }
          >
            <Model
              file={modelFile.modelData}
              rotation={rotationDegreesToEuler(90, 0, 0)}
            />
          </Viewport>
        </Sentry.ErrorBoundary>
      )
    } else if (!modelViewportError) {
      return <ViewportLoading />
    } else {
      return (
        <ViewportContainer>
          <img
            alt=""
            src="/images/inspectorNoParts.svg"
            style={{ padding: '1em', boxSizing: 'border-box', height: '100%' }}
          />
        </ViewportContainer>
      )
    }
  } else if (!isSelected) {
    return (
      <ViewportContainer>
        <img
          id="inspector-no-parts-img"
          alt=""
          src="/images/inspectorNoParts.svg"
          style={{ padding: '1em', boxSizing: 'border-box', height: '100%' }}
        />
      </ViewportContainer>
    )
  } else {
    return (
      <ViewportContainer>
        <img
          id="inspector-multiple-parts-img"
          alt=""
          src="/images/inspectorMultipleSelected.svg"
          style={{ padding: '1em', boxSizing: 'border-box', height: '100%' }}
        />
      </ViewportContainer>
    )
  }
}

const PartDetails = ({
  selectPartId,
}: {
  selectPartId: (state: RootState) => string | undefined
}) => {
  const { t, i18n } = useTranslation('inbox')
  const UNAVAILABLE = t('common:loading.unavailable')

  const selectedPartId = useSelector(selectPartId)

  const { classification, status, created_at, source } =
    useGetPartApiV1PartsPartIdGetQuery(
      {
        partId: Number(selectedPartId),
      },
      {
        selectFromResult: ({ data }) => {
          return {
            classification: data?.classification,
            status: data?.status,
            created_at: data?.created_at,
            source: data?.source,
          }
        },
        skip: selectedPartId === undefined,
      },
    )

  const getPartType = (partType: PartClassification) => {
    const classification = partClassification[partType]
    return t(
      /*
        t('other')
        t('hollowModel')
        t('solidModel')
        t('crownOrToothOrVeneer')
        t('screwInTooth')
        t('die')
        t('flatGingiva')
        t('pillowGingiva')
        t('teethRow')
        t('dentureBase')
        t('denturePartial')
        t('tryInDenture')
        t('occlusalSplint')
        t('surgicalGuide')
        t('bridge')
        t('partialFramework')
        */
      `table.partClassification.${classification}`,
    )
  }

  const getPartStatus = (status: PartStatus) => {
    return t(
      /*
      t('processing')
      t('toConfirm')
      t('toAccept')
      t('printReady')
      t('rejected')
      t('addedToJob')
      t('offline')
      t('printed')
      */
      partStatusToTranslatedKey(status),
    )
  }

  const partInformation = [
    {
      name: t('partInspector.partType'),
      value: classification ? getPartType(classification) : UNAVAILABLE,
    },
    {
      name: t('partInspector.status'),
      value: status ? getPartStatus(status) : UNAVAILABLE,
    },
    {
      name: t('partInspector.received'),
      value: created_at
        ? new Date(created_at).toLocaleString([...i18n.languages], {
            day: '2-digit',
            month: 'long',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          })
        : '-',
    },
    {
      name: t('partInspector.source'),
      value: source ?? UNAVAILABLE,
    },
  ]

  return <DetailsGrid details={partInformation} />
}

const Title = ({
  selectNumSelected,
  selectPartId,
}: {
  selectNumSelected: (state: RootState) => number
  selectPartId: (state: RootState) => string | undefined
}) => {
  const { t } = useTranslation('inbox')
  const UNAVAILABLE = t('common:loading.unavailable')

  const numSelected = useSelector(selectNumSelected)
  const selectedPartId = useSelector(selectPartId)

  const { name } = useGetPartApiV1PartsPartIdGetQuery(
    {
      partId: Number(selectedPartId),
    },
    {
      selectFromResult: ({ data }) => {
        return {
          name: data?.name,
        }
      },
      skip: selectedPartId === undefined,
    },
  )

  return (
    <>
      {(numSelected === 0 && !selectedPartId) || numSelected > 1
        ? t('partInspector.title', {
            count: numSelected,
          })
        : name ?? UNAVAILABLE}
    </>
  )
}

export const InboxInspector: FC<InboxInspectorProps> = ({
  table,
  drawer,
  currentTab,
}) => {
  const getActionBarButtons = () => {
    switch (currentTab) {
      case 'Confirm':
      case 'Review':
      case 'Accepted':
      case 'Rejected':
        return (
          <PartButtonGroup
            table={table}
            isInspectorPanel={true}
            type={currentTab.toLowerCase() as PartButtonGroupType}
          />
        )
      // Parts Page Tabs
      case 'AllParts':
      case 'PrintReady':
      case 'Sent':
        return (
          <PartButtonGroup
            table={table}
            isInspectorPanel={true}
            type={'parts'}
          />
        )
      default:
        return <></>
    }
  }

  const numSelected = (state: RootState) => selectNumChecked(state, table)

  const selectedPartId = (state: RootState) => selectDetailsItem(state, table)

  return (
    <GenericInboxInspector
      drawer={drawer}
      selectNumSelected={numSelected}
      selectPartId={selectedPartId}
      buttonGroup={getActionBarButtons()}
    />
  )
}
export const GenericInboxInspector: FC<{
  drawer: string
  selectPartId: (state: RootState) => string | undefined
  selectNumSelected: (state: RootState) => number
  buttonGroup: React.ReactNode
}> = ({ drawer, selectPartId, buttonGroup, selectNumSelected }) => {
  const { t } = useTranslation('inbox')
  const theme = useTheme()

  const selectedPartId = useSelector(selectPartId)

  const { isLoading, error } = useGetPartApiV1PartsPartIdGetQuery(
    {
      partId: Number(selectedPartId),
    },
    {
      selectFromResult: ({ isLoading, error }) => {
        return {
          isLoading,
          error,
        }
      },
      skip: selectedPartId === undefined,
    },
  )

  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  return (
    <>
      <RightDrawer
        stateName={drawer}
        width={lgDown ? '100%' : '30vw'}
        shift
        sx={{
          [theme.breakpoints.up('lg')]: {
            maxWidth: '460px',
          },
          flexGrow: '1',
          flexShrink: '0',
        }}
        padding="0px"
      >
        {error ? (
          <Box component="div">
            <img
              alt=""
              src="/images/inspectorNoParts.svg"
              style={{
                padding: '1em',
                boxSizing: 'border-box',
                height: '100%',
              }}
            />
          </Box>
        ) : (
          <ExtendedDetails
            loading={isLoading}
            title={
              <Title
                selectNumSelected={selectNumSelected}
                selectPartId={selectPartId}
              />
            }
            close={() =>
              store.dispatch(setDrawerOpen({ name: drawer, value: false }))
            }
            banner={
              <>
                <PartViewport
                  selectNumSelected={selectNumSelected}
                  selectPartId={selectPartId}
                  actionBarButtons={buttonGroup}
                />
                <Divider />
              </>
            }
            entries={{
              ...(selectedPartId && {
                [t('partInspector.partInformation')]: {
                  startOpen: true,
                  content: <PartDetails selectPartId={selectPartId} />,
                },
              }),
            }}
            footer={
              selectedPartId && (
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '6px',
                  }}
                >
                  {buttonGroup}
                </Box>
              )
            }
          />
        )}
      </RightDrawer>
    </>
  )
}

export const INBOX_INSPECTOR_STATE = 'inboxInspectorState'
export const PART_INSPECTOR_STATE = 'partInspectorState'
export type PART_INSPECTOR_STATES = 'inboxInspectorState' | 'partInspectorState'
