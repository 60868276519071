import {
  Box,
  IconButton,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { createSelector } from '@reduxjs/toolkit'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { TrayTypes } from '../../constants/Trays'
import { partClassification } from '../../constants/partClassification'
import { ensureError, localizeError } from '../../helpers'
import { useOrg } from '../../hooks/org'
import {
  clientApi,
  PaginatedTemplateReadPublic,
  PrinterTrayMapping,
  TemplateReadPublic,
  useCreateTemplateApiV1TemplatesPostMutation,
  useDeleteTemplateApiV1TemplatesTemplateIdDeleteMutation,
  useGetMaterialApiV1MaterialsMaterialIdGetQuery,
  useGetTemplatesApiV1TemplatesGetQuery,
  useUpdateTemplateApiV1TemplatesTemplateIdPostMutation,
} from '../../store/clientApi'
import {
  invalidate,
  selectCheckedIds,
  selectDetailsItem,
  selectIsChecked,
  selectNumChecked,
  selectQueryArgs,
  selectQueryIsSearching,
  selectQuerySearchValue,
  selectRtkData,
  setPerPage,
  setRtkArgs,
  setSearchValue,
  setSortField,
  setSortOrder,
} from '../../store/slices/tableSlice'
import {
  resetTemplateDialog,
  SelectedTrays,
  setTemplateDialog,
  TemplateDialogSlice,
} from '../../store/slices/templateSlice'
import { RootState, store } from '../../store/store'
import { ActionBar } from '../ActionBar/ActionBar'
import { Button } from '../Button/Button'
import { DialogBox } from '../DialogBox/DialogBox'
import { AddSvg, CloseSvg, EditSvg } from '../Icon/Icon'
import { ReduxPerPage } from '../PerPageSelector/PerPageSelector'
import { SearchField } from '../SearchField/SearchField'
import { Column, DynamicTable, Sort } from '../Table/Table'
import { TemplateDialog } from './TemplateDialog'

const TEMPLATES_TABLE_STATE_NAME = 'templates_table'

// map template dialog tray state to template supported tray data
// {[id: string]: {[id: int]: boolean}} -> {[id: string]: []number}
const templateDialogStateToSupportedTrayData = (
  template: TemplateDialogSlice,
): PrinterTrayMapping => {
  return Object.fromEntries(
    Object.keys(template.trays).map((printer) => [
      printer,
      Object.keys(template.trays[printer])
        .filter((tray) => template.trays[printer][Number(tray) as TrayTypes])
        .map((tray) => Number(tray)),
    ]),
  )
}

const isTemplateDialogFilled = (
  template: TemplateDialogSlice,
  supportedTrays: PrinterTrayMapping,
) => {
  return !(
    template.name === '' ||
    template.manufacturer === '' ||
    template.material === undefined ||
    template.thickness === undefined ||
    template.partType === undefined ||
    Object.values(supportedTrays)
      .map((trays) => trays.length)
      .filter((length) => length !== 0).length === 0
  )
}

const TemplateData = () => {
  const queryArgs = useSelector((state: RootState) =>
    selectQueryArgs(state, TEMPLATES_TABLE_STATE_NAME),
  )

  const rtkArgs = useMemo(() => {
    const defaultSortField = 'name'
    const defaultSortOrder = 'asc'
    const sortField =
      queryArgs.sortField === '' ? defaultSortField : queryArgs.sortField
    const sortOrder =
      queryArgs.sortField === '' ? defaultSortOrder : queryArgs.sortOrder

    return {
      page: queryArgs.page !== 0 ? queryArgs.page - 1 : 0,
      perPage: queryArgs.perPage,
      sorting: `${sortField}:${sortOrder === 'asc' ? '1' : '-1'}`,
      query:
        queryArgs.searchValue !== ''
          ? `name:"*${queryArgs.searchValue}*" OR material.name:"*${queryArgs.searchValue}*"`
          : undefined,
    }
  }, [queryArgs])

  useEffect(() => {
    store.dispatch(
      setRtkArgs({ name: TEMPLATES_TABLE_STATE_NAME, value: rtkArgs }),
    )
  }, [rtkArgs])

  useGetTemplatesApiV1TemplatesGetQuery(rtkArgs, {})
  return <></>
}

const EntityCell = ({ id, column }: { id: string; column: Column }) => {
  const { t } = useTranslation('templates')

  const UNAVAILABLE = t('common:loading.unavailable')

  const data = useSelector((state: RootState) =>
    selectRtkData(
      state,
      TEMPLATES_TABLE_STATE_NAME,
      clientApi.endpoints.getTemplatesApiV1TemplatesGet.select,
    )?.data?.content?.find((template) => template.id.toString() === id),
  )

  const { data: materialData } = useGetMaterialApiV1MaterialsMaterialIdGetQuery(
    {
      materialId: data?.material_id ?? 0,
    },
    {
      skip: data === undefined,
    },
  )

  const defaultManufacturer = 'Asiga'

  return (
    <Box component="div">
      {column.key === 'name' && (data?.name ?? UNAVAILABLE)}
      {column.key === 'material.name' && (materialData?.name ?? UNAVAILABLE)}
      {column.key === 'manufacturer' && defaultManufacturer}
      {column.key === 'thickness' && (data?.layer_thickness ?? UNAVAILABLE)}
      {column.key === 'partType' &&
        (data?.classification !== undefined
          ? t(
              `inbox:table.partClassification.${partClassification[data.classification]}`,
            )
          : UNAVAILABLE)}
    </Box>
  )
}

const DeleteTemplateButton = () => {
  const { t } = useTranslation('templates')
  const [openWarning, setOpenWarning] = useState(false)
  const [deleteTemplate] =
    useDeleteTemplateApiV1TemplatesTemplateIdDeleteMutation()
  const { orgId } = useOrg()

  const selected = useSelector((state: RootState) => {
    return selectCheckedIds(state, TEMPLATES_TABLE_STATE_NAME)
  }).map((id) => Number(id))

  const canDelete =
    useSelector((state: RootState) => {
      const data = selectRtkData(
        state,
        TEMPLATES_TABLE_STATE_NAME,
        clientApi.endpoints.getTemplatesApiV1TemplatesGet.select,
      )?.data
      const checked = selectCheckedIds(state, TEMPLATES_TABLE_STATE_NAME)
      return data?.content
        ?.filter((template) => checked.includes(template.id.toString()))
        .map((template) => template.organisation_id)
    })?.every((org) => org === orgId) && orgId !== undefined

  const handleDelete = async () => {
    if (selected === undefined || selected?.length === 0) return

    const result = await Promise.allSettled(
      selected.map((id) => deleteTemplate({ templateId: id }).unwrap()),
    )

    const successful = result.reduce((runningCount, res) => {
      if (res.status === 'rejected') {
        toast.error(localizeError(t, ensureError(res.reason)))
      } else if (res.status === 'fulfilled') {
        runningCount += 1
      }
      return runningCount
    }, 0)

    if (successful > 0)
      toast.success(t('label.deleteSuccess', { count: successful }))
  }

  return (
    <>
      <Button
        color="error"
        startIcon={<CloseSvg color="inherit" />}
        onClick={() => setOpenWarning(true)}
        disabled={!canDelete}
      >
        {t('common:button.delete')}
      </Button>
      <DialogBox
        title={t('title.deleteTemplate')}
        message={t('message.deleteTemplateWarning')}
        confirmColor="error"
        open={openWarning}
        setOpen={setOpenWarning}
        args={undefined}
        onClose={(approve) => {
          if (approve) handleDelete()
        }}
        confirmText={t('common:button.delete')}
        declineText={t('common:button.cancel')}
        sx={{ '.MuiPaper-root': { width: '100%', maxWidth: '700px' } }}
      />
    </>
  )
}

const EditTemplateButton = () => {
  const { t } = useTranslation('templates')
  const [templateEditOpen, setTemplateEditOpen] = useState(false)
  const [updateTemplate] =
    useUpdateTemplateApiV1TemplatesTemplateIdPostMutation()
  const { orgId } = useOrg()

  const data = useSelector((state: RootState) => {
    const selected = selectDetailsItem(state, TEMPLATES_TABLE_STATE_NAME)
    const numChecked = selectNumChecked(state, TEMPLATES_TABLE_STATE_NAME)
    return selected === undefined || numChecked !== 1
      ? undefined
      : selectRtkData(
          state,
          TEMPLATES_TABLE_STATE_NAME,
          clientApi.endpoints.getTemplatesApiV1TemplatesGet.select,
        )?.data?.content?.find(
          (template) => template.id.toString() === selected,
        )
  })

  const openTemplateEdit = () => {
    if (data === undefined) return

    // map supported trays to a value that can be displayed in a checkbox dropdown
    // {[id: string]: []number} -> {[id: string]: {[id: int]: boolean}}
    const selectedTrays: SelectedTrays = Object.fromEntries(
      Object.keys(data.supported_trays).map((category) => [
        category,
        Object.fromEntries(
          data.supported_trays[category].map((option) => [
            option.toString(),
            true,
          ]),
        ),
      ]),
    )

    store.dispatch(
      setTemplateDialog({
        name: data.name,
        manufacturer: 'Asiga',
        material: data.material_id,
        trays: selectedTrays,
        thickness: data.layer_thickness,
        partType: data.classification,
        supports: data.supports_enabled,
      }),
    )
    setTemplateEditOpen(true)
  }

  const handleUpdateTemplate = () => {
    if (data === undefined) return

    const template = store.getState().template.templateDialog

    const supportedTrays = templateDialogStateToSupportedTrayData(template)

    if (!isTemplateDialogFilled(template, supportedTrays)) {
      return false
    }

    const update = async () => {
      try {
        await updateTemplate({
          templateId: data.id,
          templateUpdatePublic: {
            name: template.name,
            material_id: template.material,
            supports_enabled: template.supports,
            layer_thickness: template.thickness,
            classification: template.partType,
            supported_trays: supportedTrays,
          },
        }).unwrap()
      } catch (err) {
        toast.error(localizeError(t, ensureError(err)))
      }
    }
    update()
  }

  return (
    <>
      <Button
        sx={{ display: data !== undefined ? 'flex' : 'none' }}
        color="primary"
        startIcon={<EditSvg color="inherit" />}
        onClick={openTemplateEdit}
        disabled={
          data?.organisation_id === undefined || data?.organisation_id !== orgId
        }
      >
        {t('common:button.edit')}
      </Button>
      <TemplateDialog
        variant="edit"
        open={templateEditOpen}
        setOpen={setTemplateEditOpen}
        onClose={(approve) => {
          if (approve) return handleUpdateTemplate()
        }}
      />
    </>
  )
}

const TemplateSearch = () => {
  const { t } = useTranslation('templates')
  const searchValue = useSelector((state: RootState) =>
    selectQuerySearchValue(state, TEMPLATES_TABLE_STATE_NAME),
  )
  return (
    <SearchField
      placeholder={t('label.search')}
      value={searchValue}
      onChange={(searchInput) => {
        store.dispatch(
          setSearchValue({
            name: TEMPLATES_TABLE_STATE_NAME,
            value: searchInput,
          }),
        )
      }}
    />
  )
}

export const TemplatesHeaderActions = () => {
  const { t } = useTranslation('common')
  const [openCreate, setOpenCreate] = useState(false)
  const openCreateDialog = () => {
    store.dispatch(resetTemplateDialog())
    setOpenCreate(true)
  }
  const [createTemplate] = useCreateTemplateApiV1TemplatesPostMutation()

  const handleTemplateCreate = () => {
    const template = store.getState().template.templateDialog
    const supportedTrays = templateDialogStateToSupportedTrayData(template)

    if (!isTemplateDialogFilled(template, supportedTrays)) {
      return false
    }

    const create = async () => {
      try {
        await createTemplate({
          templateCreatePublic: {
            name: template.name,
            material_id: template.material as any,
            supports_enabled: template.supports,
            layer_thickness: template.thickness as any,
            classification: template.partType as any,
            supported_trays: supportedTrays,
          },
        }).unwrap()
      } catch (err) {
        toast.error(localizeError(t, ensureError(err)))
      }
    }
    create()
  }

  const theme = useTheme()
  const sm_down = useMediaQuery(theme.breakpoints.down('sm'))
  const md_down = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      {sm_down ? (
        <IconButton onClick={() => openCreateDialog()}>
          <AddSvg color="inherit" />
        </IconButton>
      ) : (
        <Button
          startIcon={<AddSvg color="inherit" />}
          color="secondary"
          size={md_down ? 'small' : 'medium'}
          onClick={() => openCreateDialog()}
        >
          {t('common:button.create')}
        </Button>
      )}
      <TemplateDialog
        variant="create"
        open={openCreate}
        setOpen={setOpenCreate}
        onClose={(approve) => {
          if (approve) return handleTemplateCreate()
        }}
      />
    </>
  )
}

export const TemplatesActionBar = () => {
  const isSelected = useSelector((state: RootState) =>
    selectIsChecked(state, TEMPLATES_TABLE_STATE_NAME),
  )

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'center',
        width: '100%',
        gap: '6px',
      }}
    >
      {isSelected ? (
        <ActionBar
          handleCloseActionBar={() =>
            store.dispatch(invalidate(TEMPLATES_TABLE_STATE_NAME))
          }
          selector={(state) =>
            selectNumChecked(state, TEMPLATES_TABLE_STATE_NAME)
          }
        >
          <DeleteTemplateButton />
          <EditTemplateButton />
        </ActionBar>
      ) : (
        <>
          <TemplateSearch />
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <ReduxPerPage
              dispatch={(state) =>
                store.dispatch(
                  setPerPage({
                    name: TEMPLATES_TABLE_STATE_NAME,
                    value: state,
                  }),
                )
              }
              selector={(state) =>
                state.tables.data[TEMPLATES_TABLE_STATE_NAME]?.queryArgs
                  ?.perPage
              }
            />
          </Box>
        </>
      )}
    </Box>
  )
}

export const TemplateList = () => {
  const { t } = useTranslation('templates')
  const handleSortTable = (sort?: Sort) => {
    if (sort !== undefined && sort.key !== undefined) {
      store.dispatch(
        setSortField({ name: TEMPLATES_TABLE_STATE_NAME, value: sort.key }),
      )
      store.dispatch(
        setSortOrder({
          name: TEMPLATES_TABLE_STATE_NAME,
          value: sort.order,
        }),
      )
    } else {
      store.dispatch(
        setSortField({ name: TEMPLATES_TABLE_STATE_NAME, value: '' }),
      )
      store.dispatch(
        setSortOrder({ name: TEMPLATES_TABLE_STATE_NAME, value: 'asc' }),
      )
    }
  }

  const selectTemplateIds = useMemo(() => {
    const emptyArray: TemplateReadPublic[] = []

    return createSelector(
      [(res?: PaginatedTemplateReadPublic) => res?.content ?? emptyArray],
      (content) => content.map((template) => template.id.toString()),
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [])
  const data = useSelector((state: RootState) =>
    selectTemplateIds(
      selectRtkData(
        state,
        TEMPLATES_TABLE_STATE_NAME,
        clientApi.endpoints.getTemplatesApiV1TemplatesGet.select,
      )?.data,
    ),
  )

  const totalCount = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      TEMPLATES_TABLE_STATE_NAME,
      clientApi.endpoints.getTemplatesApiV1TemplatesGet.select,
    )
    return query?.data?.total_count ?? query?.data?.content?.length
  })

  const error = useSelector(
    (state: RootState) =>
      selectRtkData(
        state,
        TEMPLATES_TABLE_STATE_NAME,
        clientApi.endpoints.getTemplatesApiV1TemplatesGet.select,
      )?.error,
  )

  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      TEMPLATES_TABLE_STATE_NAME,
      clientApi.endpoints.getTemplatesApiV1TemplatesGet.select,
    )

    return (
      query?.isLoading && (query?.isUninitialized || query?.data === undefined)
    )
  })

  const isSearching = useSelector((state: RootState) =>
    selectQueryIsSearching(state, TEMPLATES_TABLE_STATE_NAME),
  )

  const columns: Column[] = [
    { name: t('label.templateName'), key: 'name', sortable: true },
    { name: t('label.materialName'), key: 'material.name', sortable: true },
    { name: t('label.manufacturer'), key: 'manufacturer' },
    { name: t('label.thickness'), key: 'thickness' },
    { name: t('label.partType'), key: 'partType' },
  ]

  return error ? (
    <Box
      component="div"
      sx={{
        height: '100%',
        marginTop: '2em',
      }}
    >
      {localizeError(t, ensureError(error))}
    </Box>
  ) : isLoading || data === undefined ? (
    <Box
      component="div"
      sx={{
        height: '100%',
        marginTop: '2em',
      }}
    >
      <LinearProgress />
    </Box>
  ) : (
    <DynamicTable
      stateName={TEMPLATES_TABLE_STATE_NAME}
      columns={columns}
      renderEntry={(entity) =>
        columns.map((column, index) => (
          <EntityCell key={index} id={entity} column={column} />
        ))
      }
      checkable={true}
      menuOptions={[]}
      tableData={data}
      totalNumEntities={totalCount ?? 0}
      onSort={(sort) => {
        handleSortTable(sort)
      }}
      noMatch={isSearching && totalCount === 0}
      selectable
      mapId={(template) => template}
    />
  )
}

export const Templates = () => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        pt: '0.2em',
      }}
    >
      <TemplateData />
      <TemplatesActionBar />
      <TemplateList />
    </Box>
  )
}
