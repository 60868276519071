import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface TabSlice {
  page: {
    [id: string]: string
  }
}

const initialState: TabSlice = { page: {} } satisfies TabSlice
const tabSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setTab(state, action: PayloadAction<{ tab: string; value: string }>) {
      state.page[action.payload.tab] = action.payload.value
    },
  },
  selectors: {
    getTab(state, tab: string): string | undefined {
      return state.page[tab]
    },
  },
})

export const { setTab } = tabSlice.actions
export const { getTab } = tabSlice.selectors
export default tabSlice.reducer
