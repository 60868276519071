import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { InfoCard, ProgressCard } from '../../../components/Cards/Cards'
import { deviceStateToColor } from '../../../helpers'
import { clientApi } from '../../../store/clientApi'
import { selectRtkData } from '../../../store/slices/tableSlice'
import { setTimelineDuration } from '../../../store/slices/timelineDurationSlice'
import { RootState, store } from '../../../store/store'
import { deviceMessage } from '../../../utils/getDeviceMessage'
import { humanizeDurationHoursMinutes } from '../../../utils/shortHumanizeDuration'
import { TIMELINE_TABLE_STATE_NAME } from '../Timeline'

interface TimelineRowProps {
  deviceName: string
  timelineHours: number
  timelineHoursWidth: number
}

export const TimelineRow: FC<TimelineRowProps> = ({
  deviceName,
  timelineHours,
  timelineHoursWidth,
}) => {
  const { t } = useTranslation('devices')
  const UNAVAILABLE = t('common:loading.unavailable')

  const currDevice = useSelector((state: RootState) =>
    selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )?.data?.content?.find((device) => device.thing_name === deviceName),
  )

  const maxTimelineHours = useSelector(
    (state: RootState) => state.timelineDurationSlice.hours,
  )
  const currentTime = new Date(Date.now()).setSeconds(0, 0) // update once per minute
  // Calculate the floored hour which is 2 hours before the current time
  const flooredHourTime = new Date(currentTime - 1000 * 60 * 60 * 2).setMinutes(
    0,
    0,
    0,
  )

  // Subtract 20 minutes from the floored hour time
  const timelineStartTime = new Date(flooredHourTime - 1000 * 60 * 20)

  // Memoize queued_build_times & queued_build_names
  const queued_build_names = useMemo(() => {
    return (currDevice?.status?.queued_build_names ?? []) as string[]
  }, [currDevice])
  const queued_build_times = useMemo(() => {
    return (currDevice?.status?.queued_build_times ?? []) as number[]
  }, [currDevice])
  const elapsed = currDevice?.status?.elapsed ?? 0 // seconds
  const remaining = currDevice?.status?.remaining ?? 0 // seconds
  const printTime = elapsed + remaining
  const progress =
    printTime === 0 || currDevice?.status?.connected === false
      ? 0
      : (elapsed / printTime) * 100
  const state = currDevice?.status?.state ?? ''
  const printStartTime = currentTime - elapsed * 1000
  const currentBuildName = currDevice?.status?.current_build_name ?? UNAVAILABLE

  useEffect(() => {
    // update longest row (hours) if this is longer than previous printer rows
    const all_build_times_seconds = queued_build_times.reduce(
      (partialSum, nextNum) => partialSum + nextNum,
      0,
    )

    // total hours calculation:
    // 2h 20m displayed before current time floored hour so maximum is 3hrs 19m (3.2)
    // 6m padding between print jobs (0.1 * jobs)
    // 20mins end padding (~0.3)
    // total: remaining build times + padding + padding_between_prints
    let totalHours = 0
    if (queued_build_times.length === 0 && queued_build_names.length !== 0) {
      // queued-build-times not present. Fallback to 2 hours each
      totalHours = Math.ceil(
        remaining / 60 / 60 + queued_build_names.length * 2.1 + 3.5,
      )
    } else {
      totalHours = Math.ceil(
        remaining / 60 / 60 +
          all_build_times_seconds / 60 / 60 +
          queued_build_times.length * 0.1 +
          3.5,
      )
    }
    if (maxTimelineHours < totalHours) {
      store.dispatch(setTimelineDuration(totalHours))
    }
  }, [maxTimelineHours, queued_build_times, queued_build_names, remaining])

  if (elapsed === 0 && queued_build_names.length === 0) {
    return <></>
  }

  const hoursSinceTimelineStart =
    (printStartTime - timelineStartTime.valueOf()) / 1000 / 60 / 60
  const offsetX = (timelineHoursWidth / timelineHours) * hoursSinceTimelineStart
  const cardWidth =
    (timelineHoursWidth / timelineHours) * ((elapsed + remaining) / 60 / 60)
  const remainingWidth =
    (timelineHoursWidth / timelineHours) * (remaining / 60 / 60)

  const inProgress = ['Starting', 'Cancelling', 'Pausing', 'Printing'].includes(
    state,
  )

  const secondsToPixelWidth = (seconds: number): number => {
    const hourWidth = timelineHoursWidth / timelineHours
    const mins = seconds / 60
    const hours = mins / 60
    const width = hours * hourWidth
    return width
  }

  const pixelsToMillisecondsWidth = (width: number): number => {
    // 1hr = (timelineHoursWidth / timelineHours)px
    const hourWidth = timelineHoursWidth / timelineHours
    const hours = width / hourWidth
    const mins = hours * 60
    const secs = mins * 60
    return secs * 1000
  }

  const getQueuedJobPixelOffset = (index: number): string => {
    /** Return '{number}px' string offset for the queued build index */
    if (queued_build_times[index] === undefined) {
      // missing queued build times, default to 2 hours
      return `${offsetX + cardWidth + index * ((timelineHoursWidth / timelineHours + 3) * 2) + 6}px`
    }
    const previous_build_times = queued_build_times.slice(0, index)
    const previous_builds_total_duration = previous_build_times.reduce(
      (partialSum, nextNum) => partialSum + nextNum,
      0,
    )
    const previous_builds_total_width = secondsToPixelWidth(
      previous_builds_total_duration,
    )

    // add padding
    const padding = index * 6 + 6
    return `${previous_builds_total_width + padding + offsetX + cardWidth}px`
  }

  const getQueuedJobPixelWidth = (index: number): number => {
    /** Return number pixel width for the queued build index */
    if (queued_build_times[index] === undefined) {
      // missing queued build times, default to 2 hours
      return (timelineHoursWidth / timelineHours) * 2
    }
    const mins = queued_build_times[index] / 60
    const hours = mins / 60
    return (timelineHoursWidth / timelineHours) * hours
  }

  return (
    <>
      <ProgressCard
        offsetX={`${offsetX}px`}
        width={`${cardWidth}px`}
        title={currentBuildName}
        height={'98px'}
        subtitle={
          inProgress
            ? t('devices:state.printing', {
                duration: humanizeDurationHoursMinutes(
                  pixelsToMillisecondsWidth(remainingWidth),
                ),
              })
            : deviceMessage(currDevice)
        }
        value={progress}
        color={deviceStateToColor(currDevice)}
        offsetLeftToAvoidIntersection
      />
      {queued_build_names.length > 0 &&
        queued_build_names.map((build, index) => (
          <InfoCard
            key={index}
            offsetX={getQueuedJobPixelOffset(index)}
            width={`${getQueuedJobPixelWidth(index)}px`}
            title={build}
            height={'98px'}
            subtitle={humanizeDurationHoursMinutes(
              queued_build_times[index] !== undefined
                ? queued_build_times[index] * 1000
                : pixelsToMillisecondsWidth(
                    (timelineHoursWidth / timelineHours) * 2,
                  ),
            )}
          />
        ))}
    </>
  )
}
