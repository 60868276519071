import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { ModelFileTypes } from '../../typed/ModelFileTypes'
import { getModelFileTypeFromS3URL } from '../../utils/getModelFileType'
import {
  checkModelInIndexedDB,
  getModelFromIndexedDB,
  saveModelToIndexedDB,
} from '../../utils/indexeddb'
import { clientApi } from '../clientApi'

// Helper function to fetch model data with download progress
const fetchModelWithProgress = async (dispatch: any, presignedUrl: string) => {
  return new Promise<{ data: ArrayBuffer; type: ModelFileTypes }>(
    (resolve, reject) => {
      axios
        .get(presignedUrl, {
          responseType: 'arraybuffer',
          onDownloadProgress: (progressEvent) => {
            if (progressEvent.total !== undefined) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              )
              dispatch(setModelDownloadProgress(progress))
            }
          },
        })
        .then((response: AxiosResponse<ArrayBuffer>) => {
          const modelType = getModelFileTypeFromS3URL(presignedUrl)
          const modelData = {
            data: response.data,
            type: modelType as ModelFileTypes,
          }
          resolve(modelData)
        })
        .catch((error: Error) => {
          reject(error.message)
        })
    },
  )
}

// Define a type for the state
interface ViewportModelState {
  modelDownloadProgress: number | undefined
  modelViewportError: string | undefined
  loading: boolean
}

// Define initial state
const initialState: ViewportModelState = {
  modelDownloadProgress: undefined,
  modelViewportError: undefined,
  loading: false,
}

// Slice definition
const viewportModelSlice = createSlice({
  name: 'viewport',
  initialState,
  reducers: {
    clearModel: (state) => {
      state.modelDownloadProgress = undefined
      state.modelViewportError = undefined
    },
    setModelDownloadProgress: (state, action) => {
      state.modelDownloadProgress = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(availableViewportModel.pending, (state) => {
        state.loading = true
        state.modelDownloadProgress = undefined
        state.modelViewportError = undefined
      })
      .addCase(availableViewportModel.fulfilled, (state, action) => {
        state.loading = false
        state.modelDownloadProgress = undefined
        state.modelViewportError = undefined
      })
      .addCase(availableViewportModel.rejected, (state, action) => {
        state.loading = false
        state.modelViewportError = action.payload as string
        state.modelDownloadProgress = undefined
      })
  },
})

// Action creator to set download progress
const setModelDownloadProgress = (progress: number | undefined) => ({
  type: 'viewport/setModelDownloadProgress',
  payload: progress,
})

export const availableViewportModel = createAsyncThunk<
  boolean, // return type
  {
    partId?: number
    partStatus?: number
    jobId?: number
  }, // argument type
  { rejectValue: string }
>('viewport/fetchModel', async (params, { rejectWithValue, dispatch }) => {
  dispatch(clearModel())
  const { partId, partStatus, jobId } = params

  const indexedDBPrefx = partId === undefined ? 'JOB' : 'PART'

  // Check for invalid IDs
  const validId = partId ?? jobId
  if (validId === undefined) {
    return rejectWithValue('Invalid part or job ID')
  }

  if (partId !== undefined && partStatus === undefined) {
    return rejectWithValue('Retrieving a part requires a part status')
  }

  try {
    let modelExists = undefined

    if (partStatus !== undefined) {
      // We need to retrive the model data to check if the part
      // status has changed
      modelExists = await getModelFromIndexedDB(
        validId.toString(),
        indexedDBPrefx,
        partStatus,
      )
    } else {
      // For jobs, we only need to check if the key exists
      modelExists = await checkModelInIndexedDB(
        validId.toString(),
        indexedDBPrefx,
      )
    }

    // If the model is not in IndexedDB, fetch it from the API
    if (!modelExists) {
      let presignedUrl = undefined
      if (partId !== undefined) {
        const { data: downloadData } = await dispatch(
          clientApi.endpoints.downloadPartApiV1PartsPartIdUploadedGet.initiate({
            partId: partId as number,
          }),
        )
        presignedUrl = downloadData?.presigned_url
      } else if (jobId !== undefined) {
        const { data: downloadData } = await dispatch(
          clientApi.endpoints.downloadJobPreviewApiV1JobsJobIdDownloadPreviewGet.initiate(
            {
              jobId: jobId as number,
            },
          ),
        )
        presignedUrl = downloadData?.presigned_url
      }

      if (!presignedUrl) return rejectWithValue('Failed to fetch model data')
      const modelData = await fetchModelWithProgress(dispatch, presignedUrl)

      // Save to IndexedDB after fetching, including validStatus
      await saveModelToIndexedDB(`${indexedDBPrefx}-${validId.toString()}`, {
        modelStatus: partStatus ?? -1,
        data: modelData,
        timestamp: Date.now(),
      })

      return true
    }

    return true
  } catch (error) {
    return rejectWithValue('Failed to fetch model data')
  }
})

// Actions and reducers export
export const { clearModel } = viewportModelSlice.actions
export default viewportModelSlice.reducer
