import { useTheme } from '@mui/material'
import { useBounds } from '@react-three/drei'
import { FC, useEffect, useState } from 'react'
import { BufferGeometry, DoubleSide, Euler, Vector3 } from 'three'
import { PLYLoader, STLLoader } from 'three/examples/jsm/Addons.js'
import { ModelFileTypes } from '../../typed/ModelFileTypes'

interface Props {
  file?: { data: ArrayBuffer; type: ModelFileTypes }
  outsideBounds?: boolean
  rotation?: Euler
  position?: Vector3
  visible?: boolean
  color?: string
}

export const Model: FC<Props> = ({
  file,
  outsideBounds = false,
  position = new Vector3(0, 0, 0),
  rotation = new Euler(0, 0, 0),
  color,
}) => {
  const theme = useTheme()
  const [geometry, setGeometry] = useState<BufferGeometry | undefined>()
  const bounds = useBounds()

  useEffect(() => {
    if (!outsideBounds) {
      bounds.refresh().clip().fit()
    }
  }, [geometry, bounds, outsideBounds])

  useEffect(() => {
    const loadFileGeometry = async () => {
      if (!file) {
        setGeometry(undefined)
        return
      }

      const loaders = {
        stl: STLLoader,
        ply: PLYLoader,
      }
      const LoaderClass = loaders[file.type]

      if (!LoaderClass) {
        setGeometry(undefined)
        return
      }

      try {
        const loader = new LoaderClass()
        const geo = loader.parse(file.data)
        setGeometry(geo)
      } catch (error) {
        setGeometry(undefined)
      }
    }

    loadFileGeometry()
  }, [file])

  return (
    <>
      {geometry && (
        <mesh position={position} rotation={rotation}>
          <primitive
            object={geometry}
            attach="geometry"
            onUpdate={(self: any) => {
              self.computeVertexNormals()
            }}
          />
          <meshStandardMaterial
            side={DoubleSide}
            roughness={0.5}
            color={color ?? theme.model.model}
            vertexColors={geometry?.hasAttribute('color') ? true : false}
            attach="material"
          />
        </mesh>
      )}
    </>
  )
}
