import { BuildPlatformTypes } from '../components/BuildPlatform/BuildPlatform'
import { DeviceBuildExtentsRead } from '../store/clientApi'

// https://cplusplus.com/reference/algorithm/lower_bound/
const lowerBound = (array: string[], target: string): number => {
  array.sort() // array must be sorted first
  let lower = 0
  let upper = array.length

  while (lower < upper) {
    const mid = Math.floor((lower + upper) / 2)
    if (array[mid] < target) {
      lower = mid + 1
    } else {
      upper = mid
    }
  }

  return lower
}

/**
 * Determines the correct BuildPlatform based on the device type and build extents
 * @param deviceModel Printer model
 * @param buildExtents {x, y, z} dimensions of the buildable volume
 * @returns { BuildPlatformTypes } The correct build platform type string
 */
export const getBuildPlatform = (
  deviceModel: string | undefined | null,
  buildExtents: DeviceBuildExtentsRead | undefined,
): BuildPlatformTypes => {
  if (!deviceModel) {
    // Fallback build platform type
    return 'MAX'
  }
  if (deviceModel === 'Max 2' || deviceModel === 'MAX UV385') {
    // Max 2 logic
    return 'MAX'
  } else if (deviceModel === 'MAX X UV385') {
    // Max X logic
    return 'MAX-X'
  } else if (deviceModel === 'Ultra') {
    // Ultra logic
    return 'ULTRA'
  } else if (deviceModel === 'PRO 4K UV385') {
    if (!buildExtents) return 'PRO-4K'
    // Step 1: Prepare the possible model keys
    const keys = ['PRO-4K', 'PRO-4K-0122', 'PRO-4K-0176']

    // Step 2: Determine maxSize from buildExtents (max of x or y)
    const maxSize = Math.max(buildExtents.x, buildExtents.y)

    // Step 3: Create the size string (right justified, 4 digits)
    const size = Math.round(maxSize).toString().padStart(4, '0')

    // Step 4: Build the model key with size
    const modelKey = `PRO-4K-${size}`

    if (keys.includes(modelKey)) {
      return modelKey as BuildPlatformTypes
    }

    // Step 5: Use lowerBound to find the first valid model
    const index = lowerBound(keys, modelKey)

    if (keys[index]?.startsWith('PRO-4K-')) {
      // Otherwise, return the closest match that starts with 'PRO-4K-'
      return keys[index] as BuildPlatformTypes
    }

    // Default return if nothing found
    return 'PRO-4K'
  }

  // Fallback build platform type
  return 'MAX'
}
