import {
  Box,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Vector3 } from 'three'
import { ensureError, localizeError } from '../../helpers'
import {
  JobStatus,
  useGetDeviceBuildExtentsApiV1DevicesSerialNumberBuildExtentsGetQuery,
  useGetDeviceStatusApiV1DevicesSerialNumberStatusGetQuery,
  useGetJobApiV1JobsJobIdGetQuery,
  useGetMaterialApiV1MaterialsMaterialIdGetQuery,
  useGetPartsApiV1PartsGetQuery,
} from '../../store/clientApi'
import {
  selectDetailsItem,
  selectIsOneSelected,
  selectNumChecked,
} from '../../store/slices/tableSlice'
import { availableViewportModel } from '../../store/slices/viewportModelSlice'
import { RootState, store } from '../../store/store'
import { ModelFileTypes } from '../../typed/ModelFileTypes'
import { rotationDegreesToEuler } from '../../utils/geometryUtils'
import { getBuildPlatform } from '../../utils/getBuildPlatform'
import { getModelFromIndexedDB } from '../../utils/indexeddb'
import { BuildPlatformTypes } from '../BuildPlatform/BuildPlatform'
import {
  DetailsGrid,
  ExtendedDetails,
} from '../ExtendedDetails/ExtendedDetails'
import { Edit } from '../JobEdit/JobEdit'
import { JOB_TABLE_STATE_NAME } from '../JobsList/JobsList'
import { Model } from '../Model/Model'
import {
  Viewport,
  ViewportContainer,
  ViewportLoading,
} from '../Viewport/Viewport'

interface JobDetailsProps {
  onClose?: () => void
}

const Title = () => {
  const { t } = useTranslation('jobs')
  const numSelected = useSelector((state: RootState) =>
    selectNumChecked(state, JOB_TABLE_STATE_NAME),
  )
  return (
    <>
      {t('common:label.itemSelected', {
        count: numSelected,
      })}
    </>
  )
}

interface JobPartsListProps {
  selectedJobId: string | undefined
}
const JobPartsList: FC<JobPartsListProps> = ({ selectedJobId }) => {
  const { t } = useTranslation('jobs')
  const UNAVAILABLE = t('common:loading.unavailable')

  const {
    data: partsData,
    isLoading: partsLoading,
    error: partsError,
  } = useGetPartsApiV1PartsGetQuery(
    {
      query: `job_id:${selectedJobId ?? ''}`,
      perPage: 100, // placement is limited to 100 parts
    },
    { skip: selectedJobId === undefined },
  )

  return partsError ? (
    <>{null}</>
  ) : partsLoading ? (
    <CircularProgress />
  ) : partsData?.content ? (
    <Table sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
      <TableHead>
        <TableRow>
          <TableCell>{t('label.partName')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {partsData.content.map((part) => (
          <TableRow key={`${part.id}-${part.name}`}>
            <TableCell>{part.name}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <Table sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
      <TableRow>
        <TableCell>{UNAVAILABLE}</TableCell>
      </TableRow>
    </Table>
  )
}

const JobDetails = ({ onClose }: JobDetailsProps) => {
  const { t } = useTranslation('jobs')
  const UNAVAILABLE = t('common:loading.unavailable')
  const { enableJobReview } = useFlags()
  const [buildPlatformType, setBuildPlatformType] = useState<
    BuildPlatformTypes | undefined
  >(undefined)
  const [modelFile, setModelFile] = useState<
    | {
        modelId: string
        modelData: {
          data: ArrayBuffer
          type: ModelFileTypes
        }
      }
    | undefined
  >()
  const { modelViewportError } = useSelector(
    (state: RootState) => state.viewportModel,
  )

  const selectedJobId = useSelector((state: RootState) =>
    selectDetailsItem(state, JOB_TABLE_STATE_NAME),
  )

  const isTheOnlySelected = useSelector((state: RootState) =>
    selectIsOneSelected(state, JOB_TABLE_STATE_NAME),
  )

  const {
    selectedJobMaterialId,
    selectedJobPrinterSerial,
    selectedJobStatus,
    isLoading,
    error,
  } = useGetJobApiV1JobsJobIdGetQuery(
    {
      jobId: Number(selectedJobId),
    },
    {
      selectFromResult: ({ data, isLoading, error }) => {
        return {
          selectedJobMaterialId: data?.material_id,
          selectedJobPrinterSerial: data?.printer_serial,
          selectedJobStatus: data?.status,
          isLoading,
          error,
        }
      },
      skip: selectedJobId === undefined,
    },
  )

  useEffect(() => {
    if (
      !isTheOnlySelected ||
      selectedJobId === undefined ||
      selectedJobStatus === undefined
    ) {
      return
    }
    if (modelFile && selectedJobId !== modelFile.modelId) {
      setModelFile(undefined)
    }

    const loadModelFile = async () => {
      if (selectedJobStatus < 3) {
        // Job must be at least TO_ACCEPT
        return
      }
      const modelAvailable = await store.dispatch(
        availableViewportModel({
          jobId: parseInt(selectedJobId),
        }),
      )
      if (modelAvailable) {
        const modelFileData = await getModelFromIndexedDB(selectedJobId, 'JOB')
        if (modelFileData !== null) {
          setModelFile({ modelId: selectedJobId, modelData: modelFileData })
        }
      }
    }
    loadModelFile()
  }, [isTheOnlySelected, selectedJobStatus, selectedJobId]) // eslint-disable-line react-hooks/exhaustive-deps

  const { data: materialData } = useGetMaterialApiV1MaterialsMaterialIdGetQuery(
    {
      materialId: selectedJobMaterialId ?? 0,
    },
    { skip: selectedJobMaterialId === undefined },
  )
  const { data: deviceData } =
    useGetDeviceStatusApiV1DevicesSerialNumberStatusGetQuery(
      {
        serialNumber: selectedJobPrinterSerial ?? '',
      },
      { skip: selectedJobPrinterSerial === undefined },
    )

  const jobInformation = [
    {
      name: t('label.estimatedPrintTime'),
      value: UNAVAILABLE,
    },
    {
      name: t('label.estimatedMaterial'),
      value: UNAVAILABLE,
    },
    {
      name: t('label.targetDevice'),
      value: deviceData?.status?.name ?? UNAVAILABLE,
    },
  ]

  const { data: buildExtents } =
    useGetDeviceBuildExtentsApiV1DevicesSerialNumberBuildExtentsGetQuery(
      {
        serialNumber: selectedJobPrinterSerial ?? '',
      },
      { skip: selectedJobPrinterSerial === undefined },
    )

  useEffect(() => {
    if (buildExtents) {
      const buildPlatformType = getBuildPlatform('PRO 4K UV385', buildExtents)
      setBuildPlatformType(buildPlatformType)
    }
  }, [buildExtents])

  const title =
    selectedJobId !== undefined && materialData?.name
      ? `#${selectedJobId} ${materialData.name}`
      : UNAVAILABLE

  return !isTheOnlySelected || error ? (
    <ExtendedDetails
      loading={false}
      close={onClose}
      title={<Title />}
      subtitle={error && localizeError(t, ensureError(error))}
      banner={
        <>
          <ViewportContainer sx={{ height: '100%' }}>
            <img alt="" src="/images/noBuildSelected.svg" />
          </ViewportContainer>
        </>
      }
    ></ExtendedDetails>
  ) : (
    <ExtendedDetails
      loading={isLoading}
      close={onClose}
      title={title}
      banner={
        <>
          {modelViewportError || (selectedJobStatus ?? 0) < 3 ? (
            <ViewportContainer>
              <img
                alt=""
                src="/images/noBuildSelected.svg"
                style={{
                  padding: '1em',
                  boxSizing: 'border-box',
                  height: '100%',
                }}
              />
            </ViewportContainer>
          ) : modelFile !== undefined ? (
            <Viewport
              modelName={title}
              buildPlatform={buildPlatformType}
              buildExtents={buildExtents}
              maximisedFooter={
                isTheOnlySelected && (
                  <Box
                    component="div"
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                      gap: '6px',
                    }}
                  ></Box>
                )
              }
            >
              {buildExtents && (
                <Model
                  file={modelFile.modelData}
                  position={
                    new Vector3(-buildExtents.x / 2, 0, buildExtents.y / 2)
                  }
                  rotation={rotationDegreesToEuler(-90, 0, 0)}
                />
              )}
            </Viewport>
          ) : (
            <ViewportLoading />
          )}
          <Divider />
        </>
      }
      entries={{
        [t('details.jobInformation')]: {
          startOpen: true,
          content: <DetailsGrid details={jobInformation} />,
        },
        [t('details.parts')]: {
          startOpen: true,
          content: <JobPartsList selectedJobId={selectedJobId} />,
        },
      }}
      footer={
        <Box
          component="div"
          sx={{ display: 'flex', justifyContent: 'flex-end', gap: '6px' }}
        >
          {selectedJobStatus === JobStatus.TO_ACCEPT && enableJobReview && (
            <Edit inDrawer />
          )}
        </Box>
      }
    ></ExtendedDetails>
  )
}

export { JobDetails }
