import {
  Box,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TrayTypes } from '../../constants/Trays'
import { partClassification } from '../../constants/partClassification'
import { ensureError, localizeError } from '../../helpers'
import {
  MaterialReadPublic,
  useGetMaterialsApiV1MaterialsGetQuery,
} from '../../store/clientApi'
import {
  selectTemplateDialog,
  setMaterial,
  setPartType,
  setSupports,
  setName as setTemplateName,
  setThickness,
  setTraySubCategory,
} from '../../store/slices/templateSlice'
import { RootState, store } from '../../store/store'
import { Checkbox } from '../Checkbox/Checkbox'
import { CheckboxDropdown } from '../CheckboxDropdown/CheckboxDropdown'
import { DialogBox } from '../DialogBox'

const TemplateName = () => {
  const { t } = useTranslation('templates')
  const name = useSelector(
    (state: RootState) => selectTemplateDialog(state).name,
  )
  const setName = (name: string) => {
    store.dispatch(setTemplateName(name))
  }

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="subtitle1">{t('label.templateName')}</Typography>
      <OutlinedInput
        size="small"
        value={name}
        error={name === ''}
        onChange={(e) => setName(e.target.value)}
      />
    </Box>
  )
}

const TemplateMaterial = ({
  materials,
}: {
  materials: MaterialReadPublic[]
}) => {
  const { t } = useTranslation('templates')
  const material = useSelector(
    (state: RootState) => selectTemplateDialog(state).material ?? '',
  )

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="subtitle1">{t('label.material')}</Typography>
      <Select
        variant="outlined"
        size="small"
        sx={{
          '& .MuiInputBase-root': { width: '100%' },
          'label + &': {
            marginTop: (theme) => theme.spacing(3),
          },
        }}
        value={material}
        onChange={(e) => {
          if (e.target.value !== '')
            store.dispatch(setMaterial(e.target.value as any))
        }}
        error={material === ''}
      >
        {materials.map((material) => (
          <MenuItem key={material.id} value={material.id}>
            {material.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

const TemplateTrays = () => {
  const { t } = useTranslation('templates')
  const trays = useSelector(
    (state: RootState) => selectTemplateDialog(state).trays,
  )

  const anySelected = Object.keys(trays)
    .map((category) => Object.values(trays[category]).some((value) => value))
    .some((value) => value)

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="subtitle1">
        {t('label.printerAndTrayType')}
      </Typography>
      <CheckboxDropdown<'max' | 'pro' | 'ultra', TrayTypes>
        options={{
          ultra: {
            label: t('types.printerTypes.ultra'),
            value: 'ultra',
            options: [
              {
                label: t('types.trays.endurance'),
                value: TrayTypes.endurance,
              },
              {
                label: t('types.trays.ultraGLOSS'),
                value: TrayTypes.ultraGLOSS,
              },
            ],
          },
          max: {
            label: t('types.printerTypes.max'),
            value: 'max',
            options: [
              {
                label: t('types.trays.universal'),
                value: TrayTypes.universal,
              },
              {
                label: t('types.trays.ultraGLOSS'),
                value: TrayTypes.ultraGLOSS,
              },
              {
                label: t('types.trays.lowForce'),
                value: TrayTypes.lowForce,
              },
              {
                label: t('types.trays.LIFT'),
                value: TrayTypes.LIFT,
              },
              {
                label: t('types.trays.ultraGLOSSLIFT'),
                value: TrayTypes.ultraGLOSSLIFT,
              },
            ],
          },
          pro: {
            label: t('types.printerTypes.pro'),
            value: 'pro',
            options: [
              {
                label: t('types.trays.ultraGLOSS'),
                value: TrayTypes.ultraGLOSS,
              },
              {
                label: t('types.trays.lowForce'),
                value: TrayTypes.lowForce,
              },
            ],
          },
        }}
        value={trays}
        onChange={(category, value) => {
          store.dispatch(setTraySubCategory({ name: category, value: value }))
        }}
        error={!anySelected}
      />
    </Box>
  )
}

const TemplatePartType = () => {
  const { t } = useTranslation('templates')
  const partTypesOptions = Object.keys(partClassification).filter((key) =>
    isNaN(Number(key as keyof typeof partClassification)),
  ) as (keyof typeof partClassification)[]
  const partType = useSelector(
    (state: RootState) => selectTemplateDialog(state).partType,
  )

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="subtitle1">{t('label.partType')}</Typography>
      <Select
        variant="outlined"
        size="small"
        sx={{
          '& .MuiInputBase-root': { width: '100%' },
          'label + &': {
            marginTop: (theme) => theme.spacing(3),
          },
        }}
        value={partType ?? ''}
        onChange={(e) => {
          if (e.target.value !== '')
            store.dispatch(setPartType(e.target.value as any))
        }}
        error={partType === undefined}
      >
        {partTypesOptions.map((partType) => (
          <MenuItem key={partType} value={partClassification[partType]}>
            {t(`inbox:table.partClassification.${partType}`)}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

const TemplateSupports = () => {
  const { t } = useTranslation('templates')
  const supports = useSelector(
    (state: RootState) => selectTemplateDialog(state).supports,
  )

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="subtitle1">{t('label.supports')}</Typography>
      <Box component="div">
        <Checkbox
          sx={{ padding: '0px' }}
          checked={supports}
          onChange={(e) => {
            store.dispatch(setSupports(e.target.checked))
          }}
        />
      </Box>
    </Box>
  )
}

const TemplateThickness = ({
  materials,
}: {
  materials: MaterialReadPublic[]
}) => {
  const { t } = useTranslation('templates')
  const selectedMaterial = useSelector(
    (state: RootState) => selectTemplateDialog(state).material ?? '',
  )
  const thickness = useSelector(
    (state: RootState) => selectTemplateDialog(state).thickness ?? '',
  )
  const material: MaterialReadPublic | undefined = materials.filter(
    (material) => material.id === selectedMaterial,
  )[0]

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="subtitle1">{t('label.thickness')}</Typography>
      <Select
        variant="outlined"
        size="small"
        sx={{
          '& .MuiInputBase-root': { width: '100%' },
          'label + &': {
            marginTop: (theme) => theme.spacing(3),
          },
        }}
        value={thickness}
        error={thickness === ''}
        onChange={(e) => {
          if (e.target.value !== '')
            store.dispatch(setThickness(e.target.value as any))
        }}
        disabled={selectedMaterial === ''}
      >
        {((material?.metadata as any)?.thicknessDefaults ?? []).map(
          (thickness: number) => (
            <MenuItem key={thickness} value={thickness}>
              {thickness}
            </MenuItem>
          ),
        )}
      </Select>
    </Box>
  )
}

export const TemplateDialog = ({
  variant,
  open,
  setOpen,
  onClose,
}: {
  variant: 'edit' | 'create'
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onClose: (approve: boolean) => void
}) => {
  const { t } = useTranslation('templates')
  const manufacturer = useSelector(
    (state: RootState) => selectTemplateDialog(state).manufacturer,
  )
  const {
    data: materials,
    isLoading,
    error,
  } = useGetMaterialsApiV1MaterialsGetQuery({ perPage: 1000 })

  return (
    <DialogBox
      sx={{
        '.MuiPaper-root': {
          width: '100%',
          maxWidth: '600px',
          margin: '16px',
        },
      }}
      title={
        variant === 'edit' ? t('title.editTemplate') : t('title.createTemplate')
      }
      message={
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            width: '100%',
          }}
        >
          {error ? (
            localizeError(t, ensureError(error))
          ) : isLoading || materials?.content === undefined ? (
            <LinearProgress />
          ) : (
            <>
              <TemplateName />
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                }}
              >
                <Typography variant="subtitle1">
                  {t('label.manufacturer')}
                </Typography>
                <Select
                  disabled
                  variant="outlined"
                  size="small"
                  sx={{
                    '& .MuiInputBase-root': { width: '100%' },
                    'label + &': {
                      marginTop: (theme) => theme.spacing(3),
                    },
                  }}
                  value={manufacturer}
                >
                  <MenuItem value={manufacturer}>{manufacturer}</MenuItem>
                </Select>
              </Box>
              <TemplateMaterial materials={materials?.content ?? []} />
              <TemplateTrays />
              <TemplateThickness materials={materials?.content ?? []} />
              <TemplateSupports />
              <TemplatePartType />
            </>
          )}
        </Box>
      }
      args={undefined}
      onClose={onClose}
      open={open}
      setOpen={setOpen}
      confirmText={
        variant === 'edit'
          ? t('common:button.update')
          : t('common:button.create')
      }
      declineText={t('common:button.cancel')}
    />
  )
}
