import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Paper,
  Theme,
  Typography,
} from '@mui/material'
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Color } from '../../helpers'
import { CloseSvg, ErrorOutlineSvg, MoreMenuSvg } from '../Icon/Icon'
import { Menu } from '../Menu/Menu'
import { ProgressBar } from '../ProgressBar/ProgressBar'
import { StatusBanner } from '../StatusBanner/StatusBanner'
import { StyledTooltip } from '../StyledTooltip/StyledTooltip'

interface BaseCardProps {
  content: React.ReactNode
  footer: React.ReactNode
}

interface InformationProps {
  title: string
  subtitle?: string
  info?: string
  infoIcon?: FunctionComponent
  message?: React.ReactNode
  titleFontSize?: string
  subtitleFontSize?: string
  titleNoWrap?: boolean
  offsetLeftToAvoidIntersection?: true
}

interface InfoCardProps extends InformationProps {
  offsetX?: string
  width?: string
  selected?: boolean
  message?: React.ReactNode
  onClose?: React.MouseEventHandler<HTMLButtonElement>
  onClick?: React.MouseEventHandler<HTMLDivElement>
  height?: string
}

interface ProgressCardProps extends InfoCardProps {
  offsetX?: string
  width?: string
  value: number
  message?: string
  inprogress?: boolean
  color?: Color
  icon?: React.ReactNode
  height?: string
}

interface IconCardProps extends InfoCardProps {
  icon: React.ReactNode
  width: string
  secondary?: string
}

const Information = ({
  title,
  subtitle,
  message,
  info,
  infoIcon: InfoIcon,
  titleFontSize,
  subtitleFontSize,
  titleNoWrap,
  offsetLeftToAvoidIntersection,
}: InformationProps) => {
  const informationContainerRef = useRef<HTMLDivElement | undefined>(undefined)
  const [left, setLeft] = useState(0)
  const offsetUpdateWidthPx = 1 // Pixel width interval to invoke intersection observer (i.e., every X pixels)

  const updateLeftPosition = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (informationContainerRef.current && entries.length === 1) {
        const visibleRect = entries[0]?.intersectionRect
        const refRect = informationContainerRef.current.getBoundingClientRect()

        // Make sure we only check intersections on the left side
        if (visibleRect && visibleRect.left > refRect.left) {
          setLeft(
            informationContainerRef.current.clientWidth - visibleRect.width,
          )
        }
      }
    },
    [],
  )

  useEffect(() => {
    if (!informationContainerRef.current) return
    const widthPx = informationContainerRef.current.clientWidth
    const numThresholds = Math.floor(widthPx)

    // create an array of thresholds 'offsetUpdateWidthPx' pixels wide based on the width of the card
    const dynamicThresholds = Array.from(
      { length: numThresholds },
      (_, i) => ((i + 1) * offsetUpdateWidthPx) / widthPx,
    )

    const observerOptions = {
      threshold: dynamicThresholds,
    }

    const observer = new IntersectionObserver(
      updateLeftPosition,
      observerOptions,
    )
    observer.observe(informationContainerRef.current)

    return () => {
      observer.disconnect()
    }
  }, [informationContainerRef, updateLeftPosition])

  return (
    <Box
      component="div"
      ref={
        offsetLeftToAvoidIntersection !== undefined
          ? informationContainerRef
          : undefined
      }
      sx={{
        maxWidth:
          offsetLeftToAvoidIntersection !== undefined
            ? 'calc(100% + 1em)'
            : '100%',
        width:
          offsetLeftToAvoidIntersection !== undefined
            ? 'calc(100% + 1em)'
            : '100%',
        marginLeft:
          offsetLeftToAvoidIntersection !== undefined ? '-1em' : undefined,
        paddingLeft:
          offsetLeftToAvoidIntersection !== undefined
            ? `calc(${left}px + 1em)`
            : undefined,
      }}
    >
      <Typography
        noWrap={titleNoWrap}
        variant="body1"
        sx={{
          fontWeight: '500',
          alignItems: 'center',
          gap: '0.1em',
          minWidth: info !== undefined ? 'max-content' : 'calc(100%)',
          maxWidth: 'calc(100%)',
          textOverflow: 'ellipsis',
          ...(titleFontSize && {
            fontSize: titleFontSize,
          }),
          display: info !== undefined ? 'flex' : 'inline-block',
        }}
      >
        {title}
        {(info !== undefined || InfoIcon !== undefined) && (
          <StyledTooltip title={info} placement="right" arrow>
            {InfoIcon !== undefined ? (
              <InfoIcon />
            ) : (
              <ErrorOutlineSvg color="inherit" />
            )}
          </StyledTooltip>
        )}
      </Typography>

      <Typography
        sx={{
          minWidth: '100%',
          ...(subtitleFontSize && {
            fontSize: subtitleFontSize,
          }),
        }}
        variant="subtitle1"
        color={'gray'}
        noWrap
      >
        {subtitle}
      </Typography>
      {message}
    </Box>
  )
}

const BaseCard = ({
  offsetX,
  width,
  title,
  subtitle,
  message,
  info,
  infoIcon,
  selected,
  onClose,
  onClick,
  children,
  content,
  footer,
  height,
  offsetLeftToAvoidIntersection,
}: PropsWithChildren<InfoCardProps & BaseCardProps>) => {
  const [hover, setHover] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <Card
      elevation={onClick !== undefined ? (hover ? 2 : 1) : 1}
      sx={{
        ...(selected && {
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineColor: (theme: Theme) => theme.palette.primary.main,
        }),
        borderRadius: '0.5em',
        maxHeight: height ?? 'none',
        height: height ?? 'none',
        boxSizing: 'border-box',
        fontSize: ['12px', '12px', '14px', '16px'],
        bgColor: (theme: Theme) => theme.surface.normal,
        maxWidth: width ?? 'none',
        width: width ?? 'none',
        position: offsetX === undefined ? 'relative' : 'absolute',
        left: offsetX !== undefined ? offsetX : undefined,
        cursor: onClick !== undefined ? 'pointer' : 'auto',
      }}
      onClick={(e) => {
        if (onClick && !openMenu) onClick(e)
      }}
    >
      <CardContent
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        sx={{ padding: '1em', '&:last-child': { pb: '1em' } }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Information
            title={title}
            subtitle={subtitle}
            info={info}
            infoIcon={infoIcon}
            message={message}
            titleNoWrap={offsetX !== undefined}
            offsetLeftToAvoidIntersection={offsetLeftToAvoidIntersection}
          />
          <Box
            component="div"
            sx={{
              display: () => (hover ? 'flex' : 'none'),
              alignItems: 'center',
            }}
          >
            {children && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  handleMenuClick(e)
                }}
              >
                <MoreMenuSvg color="inherit" />
              </IconButton>
            )}
            {onClose && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  onClose(e)
                }}
              >
                <CloseSvg color="inherit" />
              </IconButton>
            )}
          </Box>

          <Menu
            innerProps={{
              anchorEl: anchorEl,
              open: openMenu,
              onClose: handleMenuClose,
              onClick: (e) => {
                e.stopPropagation()
                handleMenuClose()
              },
            }}
          >
            {children || <MenuItem></MenuItem>}
          </Menu>
        </Box>
        {content}
      </CardContent>
      {footer}
    </Card>
  )
}

const ProgressCard = ({
  offsetX,
  width,
  value,
  message,
  color,
  inprogress,
  title,
  subtitle,
  info,
  infoIcon,
  selected,
  onClose,
  onClick,
  children,
  icon,
  height,
  offsetLeftToAvoidIntersection,
}: PropsWithChildren<ProgressCardProps>) => {
  return (
    <BaseCard
      offsetX={offsetX}
      width={width}
      title={title}
      subtitle={subtitle}
      selected={selected}
      onClose={onClose}
      onClick={onClick}
      info={info}
      infoIcon={infoIcon}
      content={
        <ProgressBar
          value={value}
          variant="determinate"
          color={color ?? 'info'}
        />
      }
      footer={
        <>
          {message && (
            <StatusBanner
              sx={{
                marginLeft: '0.5em',
                marginRight: '0.5em',
                borderBottomWidth: '0px',
              }}
              variant={color ?? 'info'}
              message={message}
              open={true}
              closable={false}
              inprogress={inprogress}
              icon={icon}
            />
          )}
        </>
      }
      height={height}
      offsetLeftToAvoidIntersection={offsetLeftToAvoidIntersection}
    >
      {children}
    </BaseCard>
  )
}

const InfoCard = ({
  offsetX,
  message,
  width,
  title,
  subtitle,
  info,
  selected,
  onClose,
  onClick,
  children,
  height,
  offsetLeftToAvoidIntersection,
}: PropsWithChildren<InfoCardProps>) => {
  return (
    <BaseCard
      offsetX={offsetX}
      width={width}
      title={title}
      subtitle={subtitle}
      message={message}
      selected={selected}
      info={info}
      onClose={onClose}
      onClick={onClick}
      content={<></>}
      footer={undefined}
      height={height}
      offsetLeftToAvoidIntersection={offsetLeftToAvoidIntersection}
    >
      {children}
    </BaseCard>
  )
}

const IconCard = ({
  title,
  subtitle,
  secondary,
  info,
  selected,
  width,
  icon,
  onClick,
}: IconCardProps) => {
  const [hover, setHover] = useState(false)
  return (
    <Box component="div" maxWidth={width} sx={{ margin: '1em' }}>
      <Avatar
        component={Paper}
        elevation={onClick !== undefined ? (hover ? 2 : 1) : 1}
        variant="rounded"
        sx={{
          ...(selected && {
            outlineStyle: 'solid',
            outlineWidth: '2px',
            outlineColor: (theme: Theme) => theme.palette.primary.main,
          }),
          borderRadius: '0.6em',
          width: width,
          height: width,
        }}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onClick={onClick}
      >
        {icon}
      </Avatar>
      <Box
        component="div"
        sx={{
          paddingLeft: '0.1em',
          paddingRight: '0.1em',
          marginTop: '0.5em',
        }}
      >
        <Information
          titleFontSize="1.2em"
          subtitleFontSize="0.9em"
          title={title}
          subtitle={subtitle}
          info={info}
          titleNoWrap
        />
        {secondary && (
          <Typography
            noWrap
            sx={{ fontSize: '0.9em' }}
            variant="subtitle1"
            color={'gray'}
          >
            {secondary}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
export { InfoCard, ProgressCard, IconCard }
