import { Box, Divider, Typography, useTheme } from '@mui/material'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { MachinesSvg, WarningSvg } from '../../../components/Icon/Icon'
import { StatePerPage } from '../../../components/PerPageSelector/PerPageSelector'
import { Tab, Tabs } from '../../../components/Tabs/Tabs'

interface TimelineHeaderProps {
  timelineHours: number
  setTimelineHours: Dispatch<SetStateAction<number>>
  tab: false | string
  setTab: (tab: false | string) => void
}

export const TimelineHeader: FC<TimelineHeaderProps> = ({
  tab,
  setTab,
  timelineHours,
  setTimelineHours,
}) => {
  const { t } = useTranslation('timeline')
  const theme = useTheme()
  const selectedTab = tab === false ? '' : tab
  return (
    <>
      <Typography variant="h1">{t('title.timeline')}</Typography>
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          minHeight: 'max-content',
          width: '100%',
        }}
      >
        <Tabs
          tab={tab}
          setTab={setTab}
          defaultTab="all"
          aria-label={t('label.devicesTab')}
        >
          <Tab
            selectedTab={selectedTab}
            icon={<MachinesSvg color="inherit" />}
            label={t('label.allMachines')}
            value="all"
          />
          <Tab
            selectedTab={selectedTab}
            icon={<WarningSvg color={theme.palette.warning.main} />}
            label={t('label.requiresAttention')}
            value="attention"
          />
        </Tabs>
        <Box
          component="div"
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
            boxSizing: 'border-box',
            maxHeight: '72px',
          }}
        >
          <StatePerPage
            label={t('viewHours')}
            values={[8, 12, 24]}
            perPage={timelineHours}
            setPerPage={setTimelineHours}
          />
        </Box>
      </Box>
      <Divider flexItem />
    </>
  )
}
