import { useAuth0 } from '@auth0/auth0-react'
import { jwtDecode, JwtPayload } from 'jwt-decode'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useGetOrganisationApiV1OrganisationsOrgIdGetQuery } from '../store/clientApi'
import { selectOrgId, setOrgId } from '../store/slices/orgSlice'
import { RootState, store } from '../store/store'

const useOrg = () => {
  const { getAccessTokenSilently } = useAuth0()

  const orgId = useSelector((state: RootState) => selectOrgId(state))

  useEffect(() => {
    const getOrg = async () => {
      const accessToken = await getAccessTokenSilently()

      const decoded = jwtDecode<
        JwtPayload & {
          permissions: string[]
          ['https://api.asiga.com/metadata']: { organisation: number }
        }
      >(accessToken)

      const orgId = decoded?.['https://api.asiga.com/metadata']?.organisation
      if (orgId !== undefined) store.dispatch(setOrgId(orgId))
    }
    if (orgId === undefined) getOrg()
  }, [getAccessTokenSilently, orgId])

  const {
    data: org,
    error: orgError,
    isLoading: orgIsLoading,
  } = useGetOrganisationApiV1OrganisationsOrgIdGetQuery(
    { orgId: orgId ?? -1 },
    {
      skip: orgId === undefined,
    },
  )

  return {
    orgId,
    org,
    error: orgId ? orgError : undefined,
    isLoading: orgId ? orgIsLoading : true,
  }
}

export { useOrg }
