import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DashboardLink } from './components/Dashboard/Dashboard'
import {
  DevicesSvg,
  InboxSvg,
  ManageSvg,
  PartsSvg,
  PrintJobsSvg,
  TimelineSvg,
} from './components/Icon/Icon'
import { PartStatus } from './constants/partStatus'
import { JobStatus } from './store/clientApi'
import { selectStatusBadge } from './store/slices/wsEventSlice'

const LoadLinks = () => {
  const { t } = useTranslation()
  const PrimaryLinks: DashboardLink[] = [
    {
      name: t('common:links.inbox'),
      link: '/inbox',
      icon: <InboxSvg color="inherit" />,
      badgeContent: (state) =>
        selectStatusBadge(state, 'parts', PartStatus.toConfirm) +
        selectStatusBadge(state, 'parts', PartStatus.toAccept),
      requiresSubscription: true,
      tooltip: (
        <>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '0.9rem', fontWeight: '500' }}
          >
            {t('common:links.inbox')}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              whiteSpace: 'pre-line',
              fontSize: '0.8rem',
            }}
          >
            {t('common:links.tooltips.inbox')}
          </Typography>
        </>
      ),
    },
    {
      name: t('common:links.parts'),
      link: '/parts',
      icon: <PartsSvg color="inherit" />,
      tooltip: (
        <>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '0.9rem', fontWeight: '500' }}
          >
            {t('common:links.parts')}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              whiteSpace: 'pre-line',
              fontSize: '0.8rem',
            }}
          >
            {t('common:links.tooltips.parts')}
          </Typography>
        </>
      ),
    },
    {
      name: t('common:links.printJobs'),
      link: '/jobs',
      icon: <PrintJobsSvg color="inherit" />,
      badgeContent: (state) =>
        selectStatusBadge(state, 'jobs', JobStatus.TO_ACCEPT),
      tooltip: (
        <>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '0.9rem', fontWeight: '500' }}
          >
            {t('common:links.printJobs')}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              whiteSpace: 'pre-line',
              fontSize: '0.8rem',
            }}
          >
            {t('common:links.tooltips.printJobs')}
          </Typography>
        </>
      ),
    },
    {
      name: t('common:links.devices'),
      link: '/devices',
      icon: <DevicesSvg color="inherit" />,
      tooltip: (
        <>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '0.9rem', fontWeight: '500' }}
          >
            {t('common:links.devices')}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              whiteSpace: 'pre-line',
              fontSize: '0.8rem',
            }}
          >
            {t('common:links.tooltips.devices')}
          </Typography>
        </>
      ),
    },
    {
      name: t('common:links.timeline'),
      link: '/timeline',
      icon: <TimelineSvg color="inherit" />,
      tooltip: (
        <>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '0.9rem', fontWeight: '500' }}
          >
            {t('common:links.timeline')}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              whiteSpace: 'pre-line',
              fontSize: '0.8rem',
            }}
          >
            {t('common:links.tooltips.timeline')}
          </Typography>
        </>
      ),
    },
  ]

  const FooterLinks: DashboardLink[] = [
    {
      name: t('common:links.manage'),
      link: '/manage',
      icon: <ManageSvg color="inherit" />,
      tooltip: (
        <>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '0.9rem', fontWeight: '500', marginBottom: '6px' }}
          >
            {t('common:links.manage')}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              whiteSpace: 'pre-line',
              fontSize: '0.8rem',
            }}
          >
            {t('common:links.tooltips.manage')}
          </Typography>
        </>
      ),
    },
  ]

  return {
    PrimaryLinks,
    FooterLinks,
  }
}

export { LoadLinks }
