import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TrayTypes } from '../../constants/Trays'
import { partClassification } from '../../constants/partClassification'

interface TemplateSlice {
  templateDialog: TemplateDialogSlice
}

const initialState: TemplateSlice = {
  templateDialog: {
    name: '',
    manufacturer: 'Asiga',
    material: undefined,
    trays: {},
    thickness: undefined,
    supports: true,
    partType: undefined,
  },
}

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    resetTemplateDialog(state) {
      state.templateDialog = { ...initialState.templateDialog }
    },
    setTemplateDialog(state, action: PayloadAction<TemplateDialogSlice>) {
      state.templateDialog = action.payload
    },
    setName(state, action: PayloadAction<string>) {
      state.templateDialog.name = action.payload
    },
    setMaterial(state, action: PayloadAction<number>) {
      state.templateDialog.material = action.payload
    },
    setTrays(state, action: PayloadAction<SelectedTrays>) {
      state.templateDialog.trays = action.payload
    },
    setTraySubCategory(
      state,
      action: PayloadAction<{
        name: string
        value: { [id in TrayTypes]?: boolean }
      }>,
    ) {
      state.templateDialog.trays = {
        ...state.templateDialog.trays,
        [action.payload.name]: action.payload.value,
      }
    },
    setThickness(state, action: PayloadAction<number>) {
      state.templateDialog.thickness = action.payload
    },
    setSupports(state, action: PayloadAction<boolean>) {
      state.templateDialog.supports = action.payload
    },
    setPartType(state, action: PayloadAction<partClassification>) {
      state.templateDialog.partType = action.payload
    },
  },
  selectors: {
    selectTemplateDialog(state) {
      return state.templateDialog
    },
  },
})

export interface SelectedTrays {
  [id: string]: { [id in TrayTypes]?: boolean }
}
export interface TemplateDialogSlice {
  name: string
  manufacturer: string
  material?: number
  trays: SelectedTrays
  thickness?: number
  supports: boolean
  partType?: partClassification
}

export const {
  setMaterial,
  setName,
  setPartType,
  setSupports,
  setThickness,
  setTrays,
  setTraySubCategory,
  resetTemplateDialog,
  setTemplateDialog,
} = templateSlice.actions
export const { selectTemplateDialog } = templateSlice.selectors
export default templateSlice.reducer
