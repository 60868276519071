/* istanbul ignore file */
/* eslint-disable */

import Add from '../../assets/icons/add.svg'
import All from '../../assets/icons/all.svg'
import Apps from '../../assets/icons/apps.svg'
import Back from '../../assets/icons/back.svg'
import Cancelled from '../../assets/icons/cancelled.svg'
import ChamberLightOff from '../../assets/icons/chamber-light-off.svg'
import ChamberLightOn from '../../assets/icons/chamber-light-on.svg'
import CheckIcon from '../../assets/icons/check-icon.svg'
import Close from '../../assets/icons/close.svg'
import Collapsed from '../../assets/icons/collapsed.svg'
import Complete from '../../assets/icons/complete.svg'
import Delete from '../../assets/icons/delete.svg'
import Deprioritise from '../../assets/icons/deprioritise.svg'
import Devices from '../../assets/icons/devices.svg'
import Done from '../../assets/icons/done.svg'
import Download from '../../assets/icons/download.svg'
import Duplicate from '../../assets/icons/duplicate.svg'
import Duration from '../../assets/icons/duration.svg'
import Edit from '../../assets/icons/edit.svg'
import ErrorNotification from '../../assets/icons/error-notification.svg'
import ErrorOutline from '../../assets/icons/error-outline.svg'
import Excluded from '../../assets/icons/excluded.svg'
import Expanded from '../../assets/icons/expanded.svg'
import FilterOff from '../../assets/icons/filter-off.svg'
import GestureDetected from '../../assets/icons/gesture-detected.svg'
import GestureDisabled from '../../assets/icons/gesture-disabled.svg'
import Inbox from '../../assets/icons/inbox.svg'
import Indeterminate from '../../assets/icons/indeterminate.svg'
import InfoTooltip from '../../assets/icons/info-tooltip.svg'
import Info from '../../assets/icons/info.svg'
import Integrations from '../../assets/icons/integrations.svg'
import Isometric from '../../assets/icons/isometric.svg'
import KeyboardReturn from '../../assets/icons/keyboard-return.svg'
import Launch from '../../assets/icons/launch.svg'
import LayerMarker from '../../assets/icons/layer-marker.svg'
import Left from '../../assets/icons/left.svg'
import LidOpen from '../../assets/icons/lid-open.svg'
import ListView from '../../assets/icons/list-view.svg'
import Machines from '../../assets/icons/machines.svg'
import Manage from '../../assets/icons/manage.svg'
import Materials from '../../assets/icons/materials.svg'
import Maximise from '../../assets/icons/maximise.svg'
import Menu from '../../assets/icons/menu.svg'
import Minimise from '../../assets/icons/minimise.svg'
import Missing from '../../assets/icons/missing.svg'
import MoreMenu from '../../assets/icons/more-menu.svg'
import Network from '../../assets/icons/network.svg'
import NotConnected from '../../assets/icons/not-connected.svg'
import OfflineRework from '../../assets/icons/offline-rework.svg'
import PartType from '../../assets/icons/part-type.svg'
import Part from '../../assets/icons/part.svg'
import Parts from '../../assets/icons/parts.svg'
import Paused from '../../assets/icons/paused.svg'
import PrintJob from '../../assets/icons/print-job.svg'
import PrintJobs from '../../assets/icons/print-jobs.svg'
import Printed from '../../assets/icons/printed.svg'
import Prioritise from '../../assets/icons/prioritise.svg'
import Prioritised from '../../assets/icons/prioritised.svg'
import QueueList from '../../assets/icons/queue-list.svg'
import Queued from '../../assets/icons/queued.svg'
import Redo from '../../assets/icons/redo.svg'
import Refresh from '../../assets/icons/refresh.svg'
import Release from '../../assets/icons/release.svg'
import Reprint from '../../assets/icons/reprint.svg'
import Restore from '../../assets/icons/restore.svg'
import ReviewAndReprint from '../../assets/icons/review-and-reprint.svg'
import Right from '../../assets/icons/right.svg'
import Scheduling from '../../assets/icons/scheduling.svg'
import Search from '../../assets/icons/search.svg'
import SendToMachine from '../../assets/icons/send-to-machine.svg'
import Settings from '../../assets/icons/settings.svg'
import Shuffle from '../../assets/icons/shuffle.svg'
import SortAscend from '../../assets/icons/sort-ascend.svg'
import SortDescend from '../../assets/icons/sort-descend.svg'
import Sort from '../../assets/icons/sort.svg'
import SubscriptionRestriction from '../../assets/icons/subscription-restriction.svg'
import Subtract from '../../assets/icons/subtract.svg'
import Templates from '../../assets/icons/templates.svg'
import TileView from '../../assets/icons/tile-view.svg'
import Timeline from '../../assets/icons/timeline.svg'
import ToAcceptAlt from '../../assets/icons/to-accept-alt.svg'
import ToAccept from '../../assets/icons/to-accept.svg'
import ToDo from '../../assets/icons/to-do.svg'
import ToReview from '../../assets/icons/to-review.svg'
import Todo from '../../assets/icons/todo.svg'
import Top from '../../assets/icons/top.svg'
import TrayNoFitted from '../../assets/icons/tray-no-fitted.svg'
import Tray from '../../assets/icons/tray.svg'
import Undo from '../../assets/icons/undo.svg'
import Unknown from '../../assets/icons/unknown.svg'
import Unlink from '../../assets/icons/unlink.svg'
import Upload from '../../assets/icons/upload.svg'
import Users from '../../assets/icons/users.svg'
import Warning from '../../assets/icons/warning.svg'

export const AddSvg = Add as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const AllSvg = All as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const AppsSvg = Apps as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const BackSvg = Back as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const CancelledSvg = Cancelled as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ChamberLightOffSvg = ChamberLightOff as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ChamberLightOnSvg = ChamberLightOn as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const CheckIconSvg = CheckIcon as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const CloseSvg = Close as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const CollapsedSvg = Collapsed as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const CompleteSvg = Complete as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const DeleteSvg = Delete as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const DeprioritiseSvg = Deprioritise as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const DevicesSvg = Devices as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const DoneSvg = Done as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const DownloadSvg = Download as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const DuplicateSvg = Duplicate as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const DurationSvg = Duration as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const EditSvg = Edit as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ErrorNotificationSvg = ErrorNotification as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ErrorOutlineSvg = ErrorOutline as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ExcludedSvg = Excluded as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ExpandedSvg = Expanded as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const FilterOffSvg = FilterOff as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const GestureDetectedSvg = GestureDetected as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const GestureDisabledSvg = GestureDisabled as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const InboxSvg = Inbox as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const IndeterminateSvg = Indeterminate as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const InfoTooltipSvg = InfoTooltip as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const InfoSvg = Info as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const IntegrationsSvg = Integrations as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const IsometricSvg = Isometric as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const KeyboardReturnSvg = KeyboardReturn as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const LaunchSvg = Launch as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const LayerMarkerSvg = LayerMarker as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const LeftSvg = Left as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const LidOpenSvg = LidOpen as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ListViewSvg = ListView as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const MachinesSvg = Machines as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ManageSvg = Manage as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const MaterialsSvg = Materials as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const MaximiseSvg = Maximise as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const MenuSvg = Menu as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const MinimiseSvg = Minimise as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const MissingSvg = Missing as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const MoreMenuSvg = MoreMenu as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const NetworkSvg = Network as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const NotConnectedSvg = NotConnected as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const OfflineReworkSvg = OfflineRework as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const PartTypeSvg = PartType as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const PartSvg = Part as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const PartsSvg = Parts as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const PausedSvg = Paused as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const PrintJobSvg = PrintJob as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const PrintJobsSvg = PrintJobs as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const PrintedSvg = Printed as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const PrioritiseSvg = Prioritise as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const PrioritisedSvg = Prioritised as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const QueueListSvg = QueueList as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const QueuedSvg = Queued as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const RedoSvg = Redo as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const RefreshSvg = Refresh as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ReleaseSvg = Release as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ReprintSvg = Reprint as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const RestoreSvg = Restore as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ReviewAndReprintSvg = ReviewAndReprint as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const RightSvg = Right as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const SchedulingSvg = Scheduling as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const SearchSvg = Search as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const SendToMachineSvg = SendToMachine as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const SettingsSvg = Settings as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ShuffleSvg = Shuffle as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const SortAscendSvg = SortAscend as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const SortDescendSvg = SortDescend as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const SortSvg = Sort as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const SubscriptionRestrictionSvg = SubscriptionRestriction as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const SubtractSvg = Subtract as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const TemplatesSvg = Templates as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const TileViewSvg = TileView as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const TimelineSvg = Timeline as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ToAcceptAltSvg = ToAcceptAlt as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ToAcceptSvg = ToAccept as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ToDoSvg = ToDo as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const ToReviewSvg = ToReview as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const TodoSvg = Todo as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const TopSvg = Top as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const TrayNoFittedSvg = TrayNoFitted as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const TraySvg = Tray as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const UndoSvg = Undo as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const UnknownSvg = Unknown as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const UnlinkSvg = Unlink as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const UploadSvg = Upload as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const UsersSvg = Users as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
export const WarningSvg = Warning as any as React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>
