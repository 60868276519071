import { uploadConstants } from '../constants/upload'

export const isPartFileValid = (file: File) => {
  /**
   * Function to validate file upload inputs
   * @param: file: File to validate
   * @returns: true if valid, and false otherwise
   */

  // Check if the file size is less than uploadConstants.MAX_FILE_SIZE
  const maxSize = uploadConstants.MAX_FILE_SIZE
  if (file.size > maxSize) {
    return false
  }

  // Check if the file type is one of uploadConstants.ACCEPTED_FILE_TYPES
  const acceptedTypes = uploadConstants.PARTS_ACCEPTED_FILE_TYPES.replace(
    /[.,]/g,
    '',
  ).split(' ')
  const fileType = file.name.split('.').pop()?.toLowerCase() || ''
  if (!acceptedTypes.includes(fileType)) {
    return false
  }
  return true
}
