import { CircularProgress, Typography } from '@mui/material'
import { createSelector } from '@reduxjs/toolkit'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { ensureError, localizeError } from '../../helpers'
import {
  clientApi,
  JobReadPublic,
  JobStatus,
  PaginatedJobReadPublic,
  useAcceptJobApiV1JobsJobIdAcceptPutMutation,
  useDownloadJobApiV1JobsJobIdUploadGetMutation,
  useGetDeviceWithStatusGetMutation,
  useMoveJobOfflineApiV1JobsJobIdOfflinePutMutation,
} from '../../store/clientApi'
import {
  invalidate,
  invalidateDetailsItem,
  selectCheckedIds,
  selectRtkData,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { Button } from '../Button/Button'
import { DialogBox } from '../DialogBox'
import { CloseSvg, DoneSvg, DownloadSvg, SendToMachineSvg } from '../Icon/Icon'
import { JOB_TABLE_STATE_NAME } from '../JobsList/JobsList'

const invalidateTable = () => {
  store.dispatch(invalidate(JOB_TABLE_STATE_NAME))
  store.dispatch(invalidateDetailsItem(JOB_TABLE_STATE_NAME))
}

const MoveOfflineButton = ({
  inDrawer,
  canReview,
}: {
  canReview: boolean
  inDrawer?: boolean
}) => {
  const { t } = useTranslation('jobs')
  const [rejectOpen, setRejectOpen] = useState(false)
  const [offline] = useMoveJobOfflineApiV1JobsJobIdOfflinePutMutation()
  const checkedIds = useSelector((state: RootState) =>
    selectCheckedIds(state, JOB_TABLE_STATE_NAME),
  )

  const selectJobs = useMemo(() => {
    const emptyArray: JobReadPublic[] = []

    return createSelector(
      [(res?: PaginatedJobReadPublic) => res?.content ?? emptyArray],
      (content) =>
        checkedIds
          ? (checkedIds
              .map((index) =>
                content.find((job) => job.id.toString() === index),
              )
              .filter((job) => job !== undefined) as JobReadPublic[])
          : emptyArray,
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [checkedIds])

  const selectedJobs = useSelector((state: RootState) =>
    selectJobs(
      selectRtkData(
        state,
        JOB_TABLE_STATE_NAME,
        clientApi.endpoints.getJobsApiV1JobsGet.select,
      )?.data,
    ),
  )

  const moveOffline = async () => {
    const loadingId = toast.loading(t('common:label.preparingDownload'))
    invalidateTable()
    let successCount = 0

    // Create an array of promises for all jobs
    const promises = selectedJobs.map(async (job) => {
      try {
        const download = await offline({ jobId: job.id }).unwrap()

        const link = document.createElement('a')
        link.href = download.presigned_url
        link.download = `${job.id}.zip`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        toast.custom(
          <Trans
            t={t}
            i18nKey={'message.reworkOffline'}
            components={{
              bold: <strong style={{ fontWeight: 'bold' }} />,
            }}
            values={{
              id: job.id,
            }}
          />,
          {
            icon: <DownloadSvg color="inherit" />,
          },
        )
        successCount++
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    })

    // Wait for all promises to settle (fulfilled or rejected)
    await Promise.allSettled(promises)

    toast.dismiss(loadingId)

    if (successCount > 0) {
      toast.success(
        t('common:label.downloadJobsSuccess', { count: successCount }),
      )
    }
  }

  return (
    <>
      <Button
        color="error"
        startIcon={<CloseSvg color="inherit" />}
        onClick={() => setRejectOpen(true)}
        disabled={!canReview}
        short={inDrawer}
      >
        {t('button.moveOffline')}
      </Button>
      <DialogBox
        title={t('title.moveOffline')}
        message={
          <>
            <Typography>
              {t('message.moveOffline', { count: checkedIds.length })}
            </Typography>
          </>
        }
        open={rejectOpen}
        setOpen={setRejectOpen}
        args={undefined}
        onClose={(approve) => {
          if (approve) moveOffline()
        }}
        confirmText={t('button.download')}
        confirmColor={'error'}
        declineText={t('button.cancel')}
        declineColor={'secondary'}
        ignoreClosed
      ></DialogBox>
    </>
  )
}

const AcceptButton = ({
  inDrawer,
  canReview,
}: {
  canReview: boolean
  inDrawer?: boolean
}) => {
  const { t } = useTranslation('jobs')
  const [accept] = useAcceptJobApiV1JobsJobIdAcceptPutMutation()
  const [getDevice] = useGetDeviceWithStatusGetMutation()
  const checkedIds = useSelector((state: RootState) =>
    selectCheckedIds(state, JOB_TABLE_STATE_NAME),
  )

  const selectJobs = useMemo(() => {
    const emptyArray: JobReadPublic[] = []

    return createSelector(
      [(res?: PaginatedJobReadPublic) => res?.content ?? emptyArray],
      (content) =>
        checkedIds
          ? (checkedIds
              .map((index) =>
                content.find((job) => job.id.toString() === index),
              )
              .filter((job) => job !== undefined) as JobReadPublic[])
          : emptyArray,
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [checkedIds])

  const selectedJobs = useSelector((state: RootState) =>
    selectJobs(
      selectRtkData(
        state,
        JOB_TABLE_STATE_NAME,
        clientApi.endpoints.getJobsApiV1JobsGet.select,
      )?.data,
    ),
  )

  const acceptJobs = async () => {
    const loadingId = toast.loading(t('common:label.acceptingJobs'))
    invalidateTable()
    let successCount = 0

    // Create an array of promises for all jobs
    const promises = selectedJobs.map(async (job) => {
      let device: string | undefined = undefined

      try {
        device =
          (await getDevice({ serialNumber: job.printer_serial }).unwrap())
            .status?.name ?? undefined
      } catch {
        //
      }

      toast.custom(
        <Trans
          t={t}
          i18nKey={
            'message.submittingJob' + (device !== undefined ? '_device' : '')
          }
          components={{
            bold: <strong style={{ fontWeight: 'bold' }} />,
          }}
          values={{
            device: device,
            id: job.id,
          }}
        />,
        {
          icon: <SendToMachineSvg color="inherit" />,
        },
      )

      try {
        await accept({ jobId: job.id }).unwrap()
        successCount++
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    })

    // Wait for all promises to settle (fulfilled or rejected)
    await Promise.allSettled(promises)
    toast.dismiss(loadingId)

    if (successCount > 0) {
      toast.success(
        t('common:label.acceptedJobsSuccess', { count: successCount }),
      )
    }
  }

  return (
    <Button
      startIcon={<DoneSvg color="inherit" />}
      onClick={acceptJobs}
      disabled={!canReview}
      short={inDrawer}
    >
      {t('common:button.accept')}
    </Button>
  )
}

const DownloadButton = ({
  inDrawer,
  canDownload,
}: {
  canDownload: boolean
  inDrawer?: boolean
}) => {
  const { t } = useTranslation('jobs')
  const [download] = useDownloadJobApiV1JobsJobIdUploadGetMutation()
  const checkedIds = useSelector((state: RootState) =>
    selectCheckedIds(state, JOB_TABLE_STATE_NAME),
  )

  const downloadJobs = async () => {
    let successCount = 0
    const loadingId = toast.loading(t('common:label.preparingDownload'))
    invalidateTable()

    // Create an array of promises for each job download
    const promises = checkedIds.map(async (job) => {
      try {
        const downloadUrl = await download({ jobId: parseInt(job) }).unwrap()

        const link = document.createElement('a')
        link.href = downloadUrl.presigned_url
        link.download = `${job}.zip`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        successCount++
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    })

    // Wait for all promises to settle (fulfilled or rejected)
    await Promise.allSettled(promises)

    // Dismiss the loading toast once all promises are settled
    toast.dismiss(loadingId)

    if (successCount > 0) {
      toast.success(
        t('common:label.downloadJobsSuccess', { count: successCount }),
      )
    }
  }

  return (
    <Button
      startIcon={<DownloadSvg color="inherit" />}
      onClick={() => downloadJobs()}
      disabled={!canDownload}
      short={inDrawer}
    >
      {t('button.download')}
    </Button>
  )
}

export const Edit = ({
  hideReview,
  inDrawer,
}: {
  hideReview?: boolean
  inDrawer?: boolean
}) => {
  const { enableJobReview } = useFlags()

  const selectApps = useMemo(() => {
    const emptyArray: JobReadPublic[] = []

    return createSelector(
      [
        (state: RootState): JobReadPublic[] =>
          selectRtkData(
            state,
            JOB_TABLE_STATE_NAME,
            clientApi.endpoints.getJobsApiV1JobsGet.select,
          )?.data?.content ?? emptyArray,
        (state: RootState) => selectCheckedIds(state, JOB_TABLE_STATE_NAME),
      ],
      (content, checkedIds) => {
        const jobs = checkedIds
          ? (checkedIds
              .map((index) =>
                content.find((job) => job.id.toString() === index),
              )
              .filter((job) => job !== undefined) as JobReadPublic[])
          : emptyArray
        return {
          canReview:
            jobs.every((job) => job.status === JobStatus.TO_ACCEPT) &&
            enableJobReview,
          canDownload: jobs.every((job) => job.status >= JobStatus.TO_ACCEPT),
          isSelected: checkedIds.length > 0,
        }
      },
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [enableJobReview])

  const error = useSelector(
    (state: RootState) =>
      selectRtkData(
        state,
        JOB_TABLE_STATE_NAME,
        clientApi.endpoints.getJobsApiV1JobsGet.select,
      )?.error,
  )
  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      JOB_TABLE_STATE_NAME,
      clientApi.endpoints.getJobsApiV1JobsGet.select,
    )

    return query?.isLoading && query?.isUninitialized
  })

  const { canReview, canDownload, isSelected } = useSelector(
    (state: RootState) => selectApps(state),
  )

  return error ? (
    <>{null}</>
  ) : isLoading ? (
    <CircularProgress />
  ) : isSelected ? (
    <>
      {!hideReview && (
        <>
          <MoveOfflineButton canReview={canReview} inDrawer={inDrawer} />
          <AcceptButton canReview={canReview} inDrawer={inDrawer} />
        </>
      )}
      <DownloadButton canDownload={canDownload} inDrawer={inDrawer} />
    </>
  ) : (
    <>{null}</>
  )
}
