import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { shallowEqual } from 'react-redux'
import { PartStatus as PartStatusEnum } from '../../constants/partStatus'
import { Ordering } from '../../helpers'
import { clientApi, PartReadPublic } from '../clientApi'
import { RootState } from '../store'

const initialState: PartQueue = {
  data: {},
  checked: {},
  lastChecked: {},
  detailsItem: undefined,
} satisfies PartQueue
const partQueueSlice = createSlice({
  name: 'partQueue',
  initialState,
  reducers: {
    toggleChecked(
      state,
      action: PayloadAction<{ template: string; id: string }>,
    ) {
      const isChecked =
        state.checked?.[action.payload.template]?.[action.payload.id] === true
      state.checked = {
        ...state.checked,
        [action.payload.template]: {
          ...state.checked?.[action.payload.template],
          [action.payload.id]: !isChecked,
        },
      }

      if (!isChecked)
        state.lastChecked[action.payload.template] = action.payload.id

      const checkedIds = Object.values(state.checked)
        .map((values) => Object.entries(values))
        .flat(1)
        .filter(([id, selected]) => selected)
        .map(([id]) => id.toString())

      if (checkedIds.length === 1) state.detailsItem = checkedIds[0]
      else state.detailsItem = undefined
    },
    invalidateChecked(state) {
      state.checked = {}
    },
    setChecked(
      state,
      action: PayloadAction<{
        template: string
        id: string
      }>,
    ) {
      state.checked = {
        ...state.checked,
        [action.payload.template]: {
          ...state.checked?.[action.payload.template],
          [action.payload.id]: true,
        },
      }
      state.lastChecked[action.payload.template] = action.payload.id
      state.detailsItem = action.payload.id
    },
    setCheckedTable(
      state,
      action: PayloadAction<{
        template: string
        checked: {
          [id: string]: boolean | undefined
        }
        lastChecked: string | undefined
      }>,
    ) {
      state.checked = {
        [action.payload.template]: {
          ...action.payload.checked,
        },
      }
      state.lastChecked[action.payload.template] = action.payload.lastChecked

      const checkedIds = Object.values(state.checked)
        .map((values) => Object.entries(values))
        .flat(1)
        .filter(([id, selected]) => selected)
        .map(([id]) => id.toString())

      if (checkedIds.length === 1) state.detailsItem = checkedIds[0]
    },
    setPartsPerPage(
      state,
      action: PayloadAction<{ templateId: string; perPage: number }>,
    ) {
      state.data = {
        ...state.data,
        [action.payload.templateId]: {
          ...state.data?.[action.payload.templateId],
          perPage: action.payload.perPage,
        },
      }
    },
    removeDeleted(
      state,
      action: PayloadAction<{
        template: string
        ids: string[]
      }>,
    ) {
      for (const id of action.payload.ids) {
        delete state.checked[action.payload.template]?.[id]
      }

      if (
        action.payload.ids.includes(
          state.lastChecked[action.payload.template] ?? '',
        )
      )
        state.lastChecked[action.payload.template] = undefined
    },
    setDetailsItem(state, action: PayloadAction<{ id: string | undefined }>) {
      state.detailsItem = action.payload.id
    },
    invalidateDetailsItem(state, action: PayloadAction<undefined>) {
      state.detailsItem = undefined
    },
  },
  selectors: {
    selectCheckedState(state, template, id): boolean {
      return state.checked?.[template]?.[id] === true
    },
    selectIsChecked(state): boolean {
      return (
        Object.values(state.checked)
          .filter(
            (checked) =>
              Object.values(checked ?? {}).filter((part) => part).length !== 0,
          )
          .flat(1).length > 0
      )
    },
    selectNumChecked(state): number {
      return Object.values(state.checked)
        .map(
          (checked) =>
            Object.values(checked ?? {}).filter((part) => part).length,
        )
        .flat(1)
        .reduce((accumulator, current) => accumulator + current, 0)
    },
    selectPartsPerPage(state, templateId: string): number {
      return (
        state.data?.[templateId]?.perPage ?? DEFAULT_TEMPLATE_PARTS_PER_PAGE
      )
    },
    selectTemplateChecked(
      state,
      template,
    ): { [id: string]: boolean | undefined } {
      return state.checked?.[template]
    },
    selectDetailsItem(state): string | undefined {
      return state.detailsItem
    },
  },
})

export const selectSelectedParts = createSelector(
  [
    (state: RootState) => state.api,
    (state: RootState) => state.partQueue.checked,
    (state: RootState) => state.partQueue.data,
    (state: RootState, searchValue: string) => searchValue,
    (state: RootState, searchValue: string, sortField: string) => sortField,
    (
      state: RootState,
      searchValue: string,
      sortField: string,
      sortOrder: Ordering,
    ) => sortOrder,
  ],
  (api, checked, data, searchValue, sortField, sortOrder) => {
    const templates = Object.keys(checked)
    const parts: PartReadPublic[][] = []
    for (const template of templates) {
      parts.push(
        (
          clientApi.endpoints.getPartsApiV1PartsGet.select(
            createPartQuery(
              template,
              searchValue,
              sortField,
              sortOrder,
              data?.[template]?.perPage ?? DEFAULT_TEMPLATE_PARTS_PER_PAGE,
            ),
          )({ api }).data?.content ?? []
        ).filter((part) => checked[template]?.[part.id.toString()] === true),
      )
    }
    return parts.flat(1).reduce((accumulator, current) => {
      if (!accumulator.find((part) => part.id === current.id))
        accumulator.push(current)
      return accumulator
    }, [] as PartReadPublic[])
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
)

export const statusFilter =
  'status:' +
  [
    PartStatusEnum['accepted'],
    PartStatusEnum['processing'],
    PartStatusEnum['awaitingPlacement'],
    PartStatusEnum['placing'],
  ].join(` OR status:`)

export const createPartQuery = (
  template: string,
  searchValue: string,
  sortField: string,
  sortOrder: Ordering,
  perPage: number,
) => {
  return {
    query:
      `(template_id:${template}) AND (${statusFilter})` +
      (searchValue !== '' ? `AND (name:"*${searchValue}*")` : ''),
    sorting:
      sortField !== ''
        ? `${sortField}:${sortOrder === 'asc' ? '1' : '-1'}`
        : 'id:1',
    perPage: perPage,
  }
}

export const DEFAULT_TEMPLATE_PARTS_PER_PAGE = 5
export interface PartQueue {
  data: {
    [id: string]:
      | {
          perPage: number
        }
      | undefined
  }
  checked: {
    [id: string]: {
      [id: string]: boolean | undefined
    }
  }
  lastChecked: { [id: string]: string | undefined }
  detailsItem: string | undefined
}
export const {
  toggleChecked,
  invalidateChecked,
  setChecked,
  setCheckedTable,
  setPartsPerPage,
  removeDeleted,
  setDetailsItem,
  invalidateDetailsItem,
} = partQueueSlice.actions
export const {
  selectCheckedState,
  selectIsChecked,
  selectNumChecked,
  selectPartsPerPage,
  selectTemplateChecked,
  selectDetailsItem,
} = partQueueSlice.selectors
export default partQueueSlice.reducer
