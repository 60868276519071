import { useGetOrganisationSubscriptionApiV1OrganisationsOrgIdGetQuery } from '../store/clientApi'
import { useOrg } from './org'

export const useOrgSubscription = ({ skip }: { skip?: boolean }) => {
  const { orgId } = useOrg()
  const { isLoading, error, data } =
    useGetOrganisationSubscriptionApiV1OrganisationsOrgIdGetQuery(
      {
        orgId: orgId ?? -1,
      },
      {
        skip: orgId === undefined || skip,
      },
    )

  const subEnded = data?.end != null ? new Date() > new Date(data.end) : false

  return {
    isUnsubscribed: skip ? false : (error as any)?.status === 404 || subEnded,
    subscription: data,
    isLoading,
    error: error,
  }
}
