import { Box, Typography, useTheme } from '@mui/material'
import { FC } from 'react'

interface EmptyStateProps {
  image?: string
  title?: string
  message?: string
  button?: React.ReactNode
}

/**
 * @param image Image SRC string (optional)
 * @param title Translated string for the title (optional)
 * @param message Translated string for the message body (optional)
 * @param button ReactNode for the button (optional)
 */
export const EmptyState: FC<EmptyStateProps> = ({
  image,
  title,
  message,
  button,
}) => {
  const theme = useTheme()
  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'center',
        overflow: 'auto',
        flex: 1,
      }}
    >
      <Box component="div" sx={{ flex: 1 }}></Box>
      {image && <img alt={''} style={{ maxWidth: '240px' }} src={image} />}
      {title && (
        <Typography
          variant={'h4'}
          textAlign={'center'}
          margin={'1em 0'}
          marginTop={'2.5em'}
          maxWidth={'540px'}
        >
          <strong>{title}</strong>
        </Typography>
      )}
      {message && (
        <Typography
          color={theme.surface.onVariant}
          variant={'body2'}
          textAlign={'center'}
          maxWidth={'540px'}
        >
          {message}
        </Typography>
      )}
      {button && button}
      <Box component="div" sx={{ flex: 1 }}></Box>
    </Box>
  )
}
