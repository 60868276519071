import {
  Box,
  BoxProps,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useOrgSubscription } from '../../hooks/subscription'
import { Button } from '../Button/Button'
import { SubscriptionRestrictionSvg, WarningSvg } from '../Icon/Icon'

export const UnsubscribedBanner = ({
  description,
}: {
  description: string
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const { isUnsubscribed } = useOrgSubscription({})

  return isUnsubscribed ? (
    <Box
      component="div"
      bgcolor={(theme) => theme.palette.info.light}
      sx={{
        width: '100%',
        height: '100%',
        padding: '16px',
        borderRadius: '8px',
        gap: '16px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h1"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {t('subscription:title.availableWithSubscription')}
        <Box component="div">
          <SubscriptionRestrictionSvg color={theme.palette.info.main} />
        </Box>
      </Typography>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: sm ? 'column' : 'row',
          gap: '16px',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            flexGrow: 1,
          }}
        >
          {description}
        </Typography>
        <Box component="div">
          <Button
            color={'info'}
            sx={{ color: 'white' }}
            onClick={() => window.open('/subscription', '_blank')}
          >
            {t('subscription:button.subscriptionOptions')}
          </Button>
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  )
}

export const SubscriptionPaymentIssuesBanner = forwardRef(
  (props: BoxProps, ref) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const {
      isUnsubscribed: unsubscribed,
      subscription,
      isLoading,
    } = useOrgSubscription({})
    return (
      <Box
        {...props}
        ref={ref}
        component="div"
        bgcolor={(theme) => theme.palette.warning.light}
        sx={{
          display:
            !isLoading &&
            !unsubscribed &&
            (subscription?.payment_issue ?? false)
              ? 'flex'
              : 'none',
          width: '100%',
          height: '100%',
          padding: '6px',
          paddingLeft: '8px',
          gap: '8px',
          alignItems: 'center',
          border: (theme) => `1px solid ${theme.palette.warning.main}`,
          borderRightWidth: '0px',
          borderLeftWidth: '0px',
          ...props.sx,
        }}
      >
        <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
          <WarningSvg
            color={theme.palette.warning.main}
            height="24px"
            width="24px"
          />
        </Box>
        <Typography variant="body1">
          {t('subscription:description.paymentIssue')}
        </Typography>
        <Link href="#" onClick={() => window.open('/subscription', '_blank')}>
          <Typography variant="body1">
            {t('subscription:button.manageSubscription')}
          </Typography>
        </Link>
      </Box>
    )
  },
)
