import { PartStatus } from '../constants/partStatus'
import { ModelFileTypes } from '../typed/ModelFileTypes'
import { ModelStateData } from '../typed/ModelStateData'

const DB_NAME = 'modelsCacheDB'
const DB_VERSION = 1
const STORE_NAME = 'models'
const INVALIDATION_TIMEOUT = 5 * 1000 * 60 * 60 * 24 // 5 days in milliseconds

const openDatabase = () => {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION)

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME)
      }
    }

    request.onsuccess = (event) => {
      resolve((event.target as IDBOpenDBRequest).result)
    }

    request.onerror = (event) => {
      reject((event.target as IDBOpenDBRequest).error)
    }
  })
}

const partRequiresInvalidation = (
  currentStatus: number,
  cachedStatus: number,
): boolean => {
  // Check if the model has changed - i.e., has be re-oreinted
  // where the status has changed from uploadPending (0), uploaded (1), or processing (2)
  if (
    (cachedStatus === PartStatus['uploadPending'] ||
      cachedStatus === PartStatus['uploaded'] ||
      cachedStatus === PartStatus['processing']) &&
    currentStatus !== PartStatus['uploadPending'] &&
    currentStatus !== PartStatus['uploaded'] &&
    currentStatus !== PartStatus['processing']
  ) {
    return true
  }
  return false
}

export interface modelObjectIndexedDB {
  modelStatus: number // PartReadPublic or JobReadPublic status
  data: ModelStateData // mesh data
  timestamp: number // invalidation timestamp
}

export const saveModelToIndexedDB = async (
  modelId: string,
  model: modelObjectIndexedDB,
) => {
  const db = await openDatabase()
  const transaction = db.transaction(STORE_NAME, 'readwrite')
  const store = transaction.objectStore(STORE_NAME)
  store.put(model, modelId)
  return new Promise<void>((resolve, reject) => {
    transaction.oncomplete = () => resolve()
    transaction.onerror = (event) =>
      reject((event.target as IDBTransaction).error)
  })
}

/**
 *
 * @param modelId The key of the model to retrieve
 * @returns Boolean: True if the model exists in the indexedDB store, False otherwise
 */
export const checkModelInIndexedDB = async (
  modelId: string,
  modelType: 'PART' | 'JOB',
): Promise<boolean> => {
  const db = await openDatabase()
  const transaction = db.transaction(STORE_NAME, 'readwrite')
  const store = transaction.objectStore(STORE_NAME)
  const request = store.count(`${modelType}-${modelId}`)

  request.onsuccess = function () {
    if (request.result === 0) {
      return false
    }
    return true
  }
  return false
}

export const getModelFromIndexedDB = async (
  modelId: string,
  modelType: 'PART' | 'JOB',
  modelStatus?: number,
) => {
  const db = await openDatabase()
  const transaction = db.transaction(STORE_NAME, 'readwrite')
  const store = transaction.objectStore(STORE_NAME)
  const request = store.get(`${modelType}-${modelId}`)

  return new Promise<{ data: ArrayBuffer; type: ModelFileTypes } | null>(
    (resolve, reject) => {
      request.onsuccess = (event) => {
        // Check if timestamp is beyond timeout for invalidation
        const storeItem = request.result as modelObjectIndexedDB
        if (storeItem && storeItem.data && storeItem.timestamp) {
          if (
            Math.abs(Date.now() - storeItem.timestamp) > INVALIDATION_TIMEOUT
          ) {
            // Invalidate this object and delete cache store
            store.delete(`${modelType}-${modelId}`)
            resolve(null)
          } else {
            // For parts (STLs) check if the status has changed and needs invalidation
            if (
              modelStatus !== undefined &&
              storeItem.data.type === 'stl' &&
              partRequiresInvalidation(modelStatus, storeItem.modelStatus)
            ) {
              resolve(null)
            } else {
              resolve(storeItem.data)
            }
          }
        } else {
          resolve(null)
        }
      }
      request.onerror = (event) => reject(request.error)
    },
  )
}
