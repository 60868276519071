import { Box } from '@mui/material'
import { FC } from 'react'
import { Dashboard, DashboardLink } from '../../components/Dashboard/Dashboard'
import { DevicesList } from '../../components/DevicesList/DevicesList'
import { DevicesData } from './DevicesData'

interface DevicesProps {
  primaryLinks: DashboardLink[]
  footerLinks: DashboardLink[]
}

const Devices: FC<DevicesProps> = ({ primaryLinks, footerLinks }) => {
  return (
    <>
      <DevicesData />
      <Dashboard primaryLinks={primaryLinks} footerLinks={footerLinks}>
        <Box component="div" sx={{ height: '100%' }}>
          <DevicesList />
        </Box>
      </Dashboard>
    </>
  )
}

export { Devices }
