import { Box, Button, Dialog, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useOrgSubscription } from '../../hooks/subscription'
import {
  selectShownTrialWarning,
  setShownTrialWarning,
} from '../../store/slices/orgSlice'
import { RootState, store } from '../../store/store'
import { CloseSvg } from '../Icon/Icon'

export const TrialDialog = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const close = () => {
    setOpen(false)
    store.dispatch(setShownTrialWarning(true))
  }
  const { isUnsubscribed, subscription, isLoading } = useOrgSubscription({})
  const shownTrialWarning = useSelector((state: RootState) =>
    selectShownTrialWarning(state),
  )

  const oneDayInSeconds = 24 * 60 * 60 * 1000
  let trialDaysLeft =
    subscription?.end != null
      ? Math.floor(
          (new Date(subscription.end).getTime() - new Date().getTime()) /
            oneDayInSeconds,
        )
      : undefined
  if (trialDaysLeft !== undefined && trialDaysLeft < 0) trialDaysLeft = 0

  return (
    <Dialog
      open={
        open &&
        !isLoading &&
        (subscription?.trial_ending ?? false) &&
        !shownTrialWarning
      }
      onClose={close}
      sx={{
        '.MuiPaper-root': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: {
            xs: '322px',
            sm: '322px',
            md: '480px',
            lg: '600px',
            xl: '600px',
          },
          maxHeight: '600px',
          width: '100%',
          margin: '28px',
        },
      }}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '16px',
        }}
      >
        <IconButton
          id="alert-dialog-close"
          onClick={close}
          sx={{ padding: '0px' }}
        >
          <CloseSvg color="inherit" />
        </IconButton>
      </Box>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          padding: '32px',
          paddingTop: '0px',
          gap: '32px',
          height: '100%',
        }}
      >
        <img
          alt=""
          width="120"
          height="120"
          src={
            isUnsubscribed
              ? '/images/trial-expired.svg'
              : '/images/time-running-out.svg'
          }
        />
        <Typography
          component="div"
          id="alert-dialog-title"
          variant="h1"
          sx={{
            fontSize: '1.3em',
            textAlign: 'center',
          }}
        >
          {isUnsubscribed
            ? t('subscription:title.trialEnded')
            : trialDaysLeft !== undefined && trialDaysLeft > 1
              ? t('subscription:title.trialEnding', {
                  amount: trialDaysLeft,
                })
              : t('subscription:title.trialEndingUnknown')}
        </Typography>
        <Typography variant="body1" component="div" sx={{ width: '100%' }}>
          {isUnsubscribed
            ? t('subscription:description.trialEnded')
            : t('subscription:description.trialEnding')}
        </Typography>
        <Button
          id="dialog-confirm"
          variant="contained"
          color={'primary'}
          onClick={() => window.open('/subscription', '_blank')}
        >
          {t('subscription:button.subscriptionOptions')}
        </Button>
        {!isUnsubscribed && (
          <Box
            component="div"
            sx={{
              width: '100%',
              textAlign: 'end',
              marginTop: '-20px',
            }}
          >
            <Button onClick={close}>
              {t('subscription:button.subscribeLater')}
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  )
}
