import { Box, LinearProgress } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { clientApi } from '../../store/clientApi'
import {
  selectIsChecked,
  selectQueryPerPage,
  selectQuerySearchValue,
  selectRtkData,
  setPerPage,
  setSearchValue,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { PartActionBar } from '../PartActionBar/PartActionBar'
import { PartButtonGroupType } from '../PartActionBar/buttons'
import { ReduxPerPage } from '../PerPageSelector/PerPageSelector'
import { SearchField } from '../SearchField/SearchField'
import { UnsubscribedBanner } from '../SubscriptionBanner/SubscriptionBanner'

export const PartSearch = ({ table }: { table: string }) => {
  const { t } = useTranslation('inbox')
  const searchValue = useSelector((state: RootState) =>
    selectQuerySearchValue(state, table),
  )
  const handleOnSearchChange = (searchInput: string) => {
    store.dispatch(setSearchValue({ name: table, value: searchInput }))
  }
  return (
    <SearchField
      placeholder={t('label.search')}
      value={searchValue}
      onChange={(searchInput) => handleOnSearchChange(searchInput)}
    />
  )
}

export const PartBodyHeader = ({
  table,
  actionBarType,
  enableSubscriptionBanner = false,
}: {
  table: string
  actionBarType: PartButtonGroupType
  enableSubscriptionBanner?: boolean
}) => {
  const isSelected = useSelector((state: RootState) =>
    selectIsChecked(state, table),
  )
  const { t } = useTranslation()
  return (
    <Box component="div">
      {enableSubscriptionBanner && (
        <Box component="div" sx={{ marginBottom: '12px' }}>
          <UnsubscribedBanner
            description={t('inbox:description.functionality')}
          />
        </Box>
      )}
      {isSelected ? (
        <PartActionBar
          table={table}
          isInspectorPanel={false}
          type={actionBarType}
        />
      ) : (
        <Box
          component="div"
          sx={{
            margin: 'normal',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'center',
            width: '100%',
            gap: '6px',
          }}
        >
          <PartSearch table={table} />
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <ReduxPerPage
              dispatch={(state) =>
                store.dispatch(
                  setPerPage({
                    name: table,
                    value: state,
                  }),
                )
              }
              selector={(state) => selectQueryPerPage(state, table)}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export const PartDataLoading = ({ table }: { table: string }) => {
  const contentLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      table,
      clientApi.endpoints.getPartsApiV1PartsGet.select,
    )
    return query?.data?.content === undefined && query?.isLoading
  })
  return (
    <LinearProgress sx={{ display: contentLoading ? undefined : 'none' }} />
  )
}
