import { Box } from '@mui/material'
import { createSelector } from '@reduxjs/toolkit'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { PartStatus, PartTemplate } from '..'
import { partClassification } from '../../constants/partClassification'
import {
  clientApi,
  PaginatedPartReadPublic,
  PartReadPublic,
} from '../../store/clientApi'
import {
  selectQueryIsSearching,
  selectRtkData,
  setSortField,
  setSortOrder,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { Column, DynamicTable, Sort } from '../Table/Table'

interface PartsTableProps {
  table: PART_TABLE_STATE_NAMES
}

const EntityCell = ({
  entity,
  index,
  table,
}: {
  entity: string
  index: number
  table: string
}) => {
  const { t, i18n } = useTranslation('inbox')
  const UNAVAILABLE = t('common:loading.unavailable')
  const [part, setPart] = useState<PartReadPublic | undefined>(undefined)

  const data = useSelector((state: RootState) =>
    selectRtkData(
      state,
      table,
      clientApi.endpoints.getPartsApiV1PartsGet.select,
    )?.data?.content?.find((part) => part.id.toString() === entity),
  )

  useEffect(() => {
    if (data !== undefined) setPart(data)
  }, [data])

  if (part === undefined) return <>{UNAVAILABLE}</>

  const formattedDate = new Date(part.created_at).toLocaleString(
    [...i18n.languages],
    {
      day: '2-digit',
      month: 'long',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    },
  )

  const cells =
    table === PARTS_TABLE_STATE_NAME
      ? [
          <>{part.name.toString()}</>,
          <>
            {t(
              /*
    t('other')
    t('hollowModel')
    t('solidModel')
    t('crownOrToothOrVeneer')
    t('screwInTooth')
    t('die')
    t('flatGingiva')
    t('pillowGingiva')
    t('teethRow')
    t('dentureBase')
    t('denturePartial')
    t('tryInDenture')
    t('occlusalSplint')
    t('surgicalGuide')
    t('bridge')
    t('partialFramework')
    */
              `table.partClassification.${partClassification[part.classification]}`,
            )}
          </>,
          <>
            {typeof part.template_id === 'number' ? (
              <PartTemplate templateId={part.template_id} />
            ) : (
              '-'
            )}
          </>,
          <PartStatus status={part.status} />,
        ]
      : [
          <>{part.name.toString()}</>,
          <>
            {t(
              /*
    t('other')
    t('hollowModel')
    t('solidModel')
    t('crownOrToothOrVeneer')
    t('screwInTooth')
    t('die')
    t('flatGingiva')
    t('pillowGingiva')
    t('teethRow')
    t('dentureBase')
    t('denturePartial')
    t('tryInDenture')
    t('occlusalSplint')
    t('surgicalGuide')
    t('bridge')
    t('partialFramework')
    */
              `table.partClassification.${partClassification[part.classification]}`,
            )}
          </>,
          <>
            {typeof part.template_id === 'number' ? (
              <PartTemplate templateId={part.template_id} />
            ) : (
              '-'
            )}
          </>,
          <>{part.source === '' ? t('table.userUpload') : part.source}</>,
          <PartStatus status={part.status} />,
          <>{formattedDate}</>,
        ]

  return cells[index]
}

export type PART_TABLE_STATE_NAMES = 'inboxTableState' | 'partsTableState'
export const INBOX_TABLE_STATE_NAME = 'inboxTableState'
export const PARTS_TABLE_STATE_NAME = 'partsTableState'

export const PartsTable: FC<PartsTableProps> = ({ table }) => {
  const { t } = useTranslation('inbox')

  const isSearching = useSelector((state: RootState) =>
    selectQueryIsSearching(state, table),
  )

  const selectPartIds = useMemo(() => {
    const emptyArray: PartReadPublic[] = []

    return createSelector(
      [(res?: PaginatedPartReadPublic) => res?.content ?? emptyArray],
      (content) => content.map((part) => part.id.toString()),
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [])

  const data = useSelector((state: RootState) =>
    selectPartIds(
      selectRtkData(
        state,
        table,
        clientApi.endpoints.getPartsApiV1PartsGet.select,
      )?.data,
    ),
  )

  const totalCount = useSelector(
    (state: RootState) =>
      selectRtkData(
        state,
        table,
        clientApi.endpoints.getPartsApiV1PartsGet.select,
      )?.data?.total_count,
  )

  const isPartsPage = table === PARTS_TABLE_STATE_NAME

  const getColumns = (): Column[] => {
    if (isPartsPage) {
      const partsColumns: Column[] = [
        { name: t('title.name'), key: 'name', sortable: true, hover: false },
        {
          name: t('title.partType'),
          key: 'classification',
          sortable: true,
          hover: false,
        },
        {
          name: t('title.template'),
          key: 'template.name',
          sortable: true,
          hover: false,
        },
        {
          name: t('title.status'),
          key: 'status',
          sortable: true,
          hover: false,
        },
      ]
      return partsColumns
    }
    const inboxColumns: Column[] = [
      { name: t('title.name'), key: 'name', sortable: true, hover: false },
      {
        name: t('title.partType'),
        key: 'classification',
        sortable: true,
        hover: false,
      },
      {
        name: t('title.template'),
        key: 'template.name',
        sortable: true,
        hover: false,
      },
      { name: t('title.source'), key: 'source', sortable: true, hover: false },
      { name: t('title.status'), key: 'status', sortable: true, hover: false },
      {
        name: t('title.received'),
        key: 'created_at',
        sortable: true,
        hover: false,
      },
    ]
    return inboxColumns
  }
  const columns = getColumns()

  const handleSortTable = (sort?: Sort) => {
    if (sort !== undefined && sort.key !== undefined) {
      store.dispatch(setSortField({ name: table, value: sort.key }))
      store.dispatch(
        setSortOrder({
          name: table,
          value: sort.order,
        }),
      )
    } else {
      store.dispatch(setSortField({ name: table, value: '' }))
      store.dispatch(setSortOrder({ name: table, value: 'asc' }))
    }
  }

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        width: '100%',
        overflowX: 'auto',
        paddingBottom: '30px',
        boxSizing: 'border-box',
      }}
    >
      <DynamicTable
        stateName={table}
        columns={columns}
        renderEntry={(entity) =>
          columns.map((column, index) => (
            <EntityCell table={table} entity={entity} index={index} />
          ))
        }
        checkable={true}
        menuOptions={[]}
        tableData={data}
        totalNumEntities={totalCount ?? 0}
        onSort={(sort) => {
          handleSortTable(sort)
        }}
        noMatch={isSearching && totalCount === 0}
        selectable
        mapId={(part) => part}
      />
    </Box>
  )
}
