import { combineReducers, configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { clientApi, UserReadDto } from './clientApi'
import appsSlice from './slices/appsSlice'
import devicesSlice from './slices/devicesSlice'
import downloadingPartsSlice from './slices/downloadingPartsSlice'
import orgSlice from './slices/orgSlice'
import partQueueSlice from './slices/partQueueSlice'
import partUploadsSlice from './slices/partUploadsSlice'
import { uploadsListener } from './slices/partUploadsSlice'
import rightDrawerSlice from './slices/rightDrawerSlice'
import tabSlice from './slices/tabSlice'
import tableSlice from './slices/tableSlice'
import templateSlice from './slices/templateSlice'
import timelineDurationSlice from './slices/timelineDurationSlice'
import viewportModelSlice from './slices/viewportModelSlice'
import { WebsocketEventMiddleware, wsEventSlice } from './slices/wsEventSlice'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  attachReduxState: true,
})

const RootReducer = combineReducers({
  [clientApi.reducerPath]: clientApi.reducer,
  devices: devicesSlice,
  tables: tableSlice,
  uploads: partUploadsSlice,
  downloadingParts: downloadingPartsSlice,
  apps: appsSlice,
  rightDrawers: rightDrawerSlice,
  timelineDurationSlice: timelineDurationSlice,
  wsEvents: wsEventSlice.reducer,
  tabs: tabSlice,
  organisation: orgSlice,
  viewportModel: viewportModelSlice,
  template: templateSlice,
  partQueue: partQueueSlice,
})

export const store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(uploadsListener.middleware)
      .concat(clientApi.middleware)
      .concat(WebsocketEventMiddleware()),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
})

export const patchUsers = (users: UserReadDto[]) => {
  store.dispatch(
    clientApi.util.updateQueryData(
      'searchUsersApiV1UsersSearchGet',
      {},
      (draftUsers) => {
        draftUsers.content?.concat(users)
      },
    ),
  )
}

export type RootState = ReturnType<typeof RootReducer>
export type AppDispatch = typeof store.dispatch
