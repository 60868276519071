import {
  Box,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { createSelector } from '@reduxjs/toolkit'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { ensureError, localizeError } from '../../helpers'
import {
  clientApi,
  JobReadPublic,
  JobStatus,
  PaginatedJobReadPublic,
  useGetDeviceStatusApiV1DevicesSerialNumberStatusGetQuery,
  useGetMaterialApiV1MaterialsMaterialIdGetQuery,
  useGetPartsApiV1PartsGetQuery,
} from '../../store/clientApi'
import {
  invalidateCanCollapsible,
  selectQueryIsSearching,
  selectRtkData,
  setCanCollapsible,
  setSortField,
  setSortOrder,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { JobStatusIndicator } from '../JobStatus/JobStatus'
import { Column, DynamicTable, Sort } from '../Table/Table'

const NoJobs = () => {
  const { t } = useTranslation('jobs')
  return (
    <>
      <Box component="div" sx={{ flexGrow: 1 }}></Box>
      <Typography textAlign={'center'} margin={'1em 0'} marginTop={'2.5em'}>
        {t('message.noJobs')}
      </Typography>
      <Box component="div" sx={{ flexGrow: 1 }}></Box>
    </>
  )
}

const EntityCell = ({
  jobId,
  column,
  index,
}: {
  jobId: string
  column: Column
  index: number
}) => {
  const theme = useTheme()
  const { t } = useTranslation('jobs')

  const MATERIAL = t('title.material')
  const JOB_NUMBER = t('title.jobNumber')
  const DEVICE = t('title.device')
  const STATUS = t('title.status')
  const UNAVAILABLE = t('common:loading.unavailable')

  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const [job, setJob] = useState<JobReadPublic | undefined>(undefined)

  const data = useSelector((state: RootState) =>
    selectRtkData(
      state,
      JOB_TABLE_STATE_NAME,
      clientApi.endpoints.getJobsApiV1JobsGet.select,
    )?.data?.content?.find((job) => job.id.toString() === jobId),
  )

  useEffect(() => {
    if (data !== undefined) setJob(data)
  }, [data])

  const { data: materialData } = useGetMaterialApiV1MaterialsMaterialIdGetQuery(
    {
      materialId: job?.material_id ?? 0,
    },
    {
      skip: job === undefined,
    },
  )
  const { data: deviceData } =
    useGetDeviceStatusApiV1DevicesSerialNumberStatusGetQuery(
      {
        serialNumber: job?.printer_serial ?? '',
      },
      {
        skip: job === undefined,
      },
    )

  if (job === undefined) return <>{UNAVAILABLE}</>
  return (
    <Box component="div">
      {column.name === MATERIAL && (materialData?.name ?? UNAVAILABLE)}
      {column.name === JOB_NUMBER && `#${job.id}`}
      {column.name === DEVICE && (deviceData?.status?.name ?? UNAVAILABLE)}
      {column.name === STATUS && (
        <JobStatusIndicator
          iconOnly={lgDown}
          jobStatus={job.status as JobStatus}
        />
      )}
    </Box>
  )
}

const CollapsibleDetails = ({
  job,
  selected,
  index,
  visible,
}: {
  job: string
  selected: boolean
  index: number
  visible: boolean
}) => {
  const { t } = useTranslation('jobs')
  const UNAVAILABLE = t('common:loading.unavailable')

  const {
    data: partsData,
    isLoading,
    error,
  } = useGetPartsApiV1PartsGetQuery(
    {
      query: `job_id:${job}`,
      perPage: 100, // placement is limited to 100 parts
    },
    {
      skip: !visible,
    },
  )

  useEffect(() => {
    if (!isLoading) {
      store.dispatch(
        setCanCollapsible({
          name: JOB_TABLE_STATE_NAME,
          index: job,
          value: true,
        }),
      )
    }
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!visible ? (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={8}></TableCell>
        </TableRow>
      ) : error ? (
        <TableRow selected={selected}>
          <TableCell style={{ padding: 0 }} colSpan={8}>
            <Box
              component="div"
              sx={{
                padding: '0.5em',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {UNAVAILABLE}
            </Box>
          </TableCell>
        </TableRow>
      ) : isLoading ? (
        <TableRow selected={selected}>
          <TableCell style={{ padding: 0 }} colSpan={8}>
            <LinearProgress />
          </TableCell>
        </TableRow>
      ) : partsData?.content !== undefined && partsData?.content?.length > 0 ? (
        <>
          {partsData.content.map((part) => (
            <TableRow key={`${part.id}`} selected={selected}>
              <TableCell colSpan={2}></TableCell>
              <TableCell colSpan={1}>{part.name}</TableCell>
              <TableCell colSpan={3}></TableCell>
            </TableRow>
          ))}
        </>
      ) : (
        <TableRow selected={selected} className={'EmptyRow'}>
          <TableCell colSpan={8}>
            <Box
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {t('message.noParts')}
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const JOB_TABLE_STATE_NAME = 'jobTable'
const SORT_FIELDS = ['material.name', 'id', 'printer_serial', 'status']

const JobsList = () => {
  const theme = useTheme()

  const { t } = useTranslation('jobs')

  const MATERIAL = t('title.material')
  const JOB_NUMBER = t('title.jobNumber')
  const DEVICE = t('title.device')
  const STATUS = t('title.status')

  const isSearching = useSelector((state: RootState) =>
    selectQueryIsSearching(state, JOB_TABLE_STATE_NAME),
  )
  const selectJobIds = useMemo(() => {
    const emptyArray: JobReadPublic[] = []

    return createSelector(
      [(res?: PaginatedJobReadPublic) => res?.content ?? emptyArray],
      (content) => content.map((part) => part.id.toString()),
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [])

  const data = useSelector((state: RootState) =>
    selectJobIds(
      selectRtkData(
        state,
        JOB_TABLE_STATE_NAME,
        clientApi.endpoints.getJobsApiV1JobsGet.select,
      )?.data,
    ),
  )
  const totalCount = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      JOB_TABLE_STATE_NAME,
      clientApi.endpoints.getJobsApiV1JobsGet.select,
    )
    return query?.data?.total_count ?? query?.data?.content?.length
  })
  const error = useSelector(
    (state: RootState) =>
      selectRtkData(
        state,
        JOB_TABLE_STATE_NAME,
        clientApi.endpoints.getJobsApiV1JobsGet.select,
      )?.error,
  )
  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      JOB_TABLE_STATE_NAME,
      clientApi.endpoints.getJobsApiV1JobsGet.select,
    )

    return (
      query?.isLoading && (query?.isUninitialized || query?.data === undefined)
    )
  })

  useEffect(() => {
    store.dispatch(invalidateCanCollapsible({ name: JOB_TABLE_STATE_NAME }))
  }, [])

  const columns: Column[] = useMediaQuery(theme.breakpoints.up('sm'))
    ? [
        { name: MATERIAL, sortable: true, key: SORT_FIELDS[0] },
        { name: JOB_NUMBER, sortable: true, key: SORT_FIELDS[1] },
        { name: DEVICE, sortable: true, key: SORT_FIELDS[2] },
        { name: STATUS, sortable: true, key: SORT_FIELDS[3] },
      ]
    : [
        { name: MATERIAL, sortable: true, key: SORT_FIELDS[0] },
        { name: JOB_NUMBER, sortable: true, key: SORT_FIELDS[1] },
        { name: STATUS, sortable: true, key: SORT_FIELDS[3] },
      ]
  const checkable = useMediaQuery(theme.breakpoints.up('sm'))

  const handleSortTable = (sort?: Sort) => {
    if (sort !== undefined && sort.key !== undefined) {
      store.dispatch(
        setSortField({ name: JOB_TABLE_STATE_NAME, value: sort.key }),
      )
      store.dispatch(
        setSortOrder({
          name: JOB_TABLE_STATE_NAME,
          value: sort.order,
        }),
      )
    } else {
      store.dispatch(setSortField({ name: JOB_TABLE_STATE_NAME, value: '' }))
      store.dispatch(setSortOrder({ name: JOB_TABLE_STATE_NAME, value: 'asc' }))
    }
  }

  return (
    <>
      {error ? (
        <Box
          component="div"
          sx={{
            height: '100%',
            marginTop: '2em',
          }}
        >
          {localizeError(t, ensureError(error))}
        </Box>
      ) : isLoading || data === undefined ? (
        <Box
          component="div"
          sx={{
            height: '100%',
            marginTop: '2em',
          }}
        >
          <LinearProgress />
        </Box>
      ) : (
        <>
          <DynamicTable
            stateName={JOB_TABLE_STATE_NAME}
            checkable={checkable}
            columns={columns}
            tableData={data}
            totalNumEntities={totalCount ?? 0}
            onSort={handleSortTable}
            noMatch={isSearching && totalCount === 0}
            renderEntry={(job: string, jobIndex: number) =>
              columns.map((column, index) => (
                <EntityCell
                  key={index}
                  column={column}
                  jobId={job}
                  index={jobIndex}
                />
              ))
            }
            renderCollapsible={(visible, job, selected, index) => (
              <CollapsibleDetails
                visible={visible}
                job={job}
                selected={selected}
                index={index}
              />
            )}
            menuOptions={[]}
            sx={{
              svg: {
                color: 'primary.main',
              },
              flexGrow: isSearching && totalCount === 0 ? 0 : 1,
            }}
            selectable
            mapId={(job) => job}
          />
          {!isSearching && totalCount === 0 && <NoJobs />}
        </>
      )}
    </>
  )
}

export { JobsList, JOB_TABLE_STATE_NAME, SORT_FIELDS }
