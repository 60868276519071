import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  SxProps,
  Theme,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Color } from '../../helpers'
import { Button } from '../Button/Button'
import { CloseSvg } from '../Icon/Icon'

interface DialogProps<T> {
  title: string
  message: React.ReactNode
  args: T
  onClose: (approve: boolean, args: T) => boolean | any
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  hideConfirm?: boolean
  confirmText?: string
  confirmColor?: Color
  declineText?: string
  declineColor?: Color
  ignoreClosed?: boolean
  sx?: SxProps<Theme>
}

const DialogBox = <T,>(props: DialogProps<T>) => {
  const { t } = useTranslation()
  const handleClose = (result: boolean | object) => {
    if (typeof result !== 'boolean' && props.ignoreClosed) {
      props.setOpen(false)
      return
    }
    if (props.onClose(result === true, props.args) !== false)
      props.setOpen(false)
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ ...props.sx }}
    >
      <Box
        component="div"
        sx={{ display: 'flex', width: '100%', alignItems: 'center' }}
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <Box
          component="div"
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingRight: '16px',
          }}
        >
          <IconButton
            id="alert-dialog-close"
            onClick={(e: React.SyntheticEvent) => handleClose(e)}
            sx={{ height: '40px' }}
          >
            <CloseSvg color="inherit" />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <DialogContent>{props.message}</DialogContent>
      {!props.hideConfirm && (
        <>
          <Divider />
          <DialogActions sx={{ padding: '1em', gap: '0.5em' }}>
            <Button
              id="dialog-decline"
              color={props.declineColor ?? 'secondary'}
              onClick={() => handleClose(false)}
            >
              {props.declineText ?? t('common:dialog.no')}
            </Button>
            <Button
              id="dialog-confirm"
              color={props.confirmColor ?? 'primary'}
              onClick={() => handleClose(true)}
              autoFocus
            >
              {props.confirmText ?? t('common:dialog.yes')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export { DialogBox }
