import humanizeDuration, {
  humanizer,
  HumanizerOptions,
} from 'humanize-duration'

const CHAR_WIDTH = 10 // roughly a single character width in pixels

const shortEnglishHumanizer = humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'min',
      s: () => 's',
      ms: () => 'ms',
    },
  },
})

/**
 *
 * @param durationMS duration to humanize in milliseconds
 * @param options HumanizerOptions such as language and fallback
 * @param shortenThresholdWidth min-width in pixels when the the short-hand humanized duration should be used
 * @returns String of the humanized duration
 */
export const humanizeDurationWithWidth = (
  durationMS: number,
  options: HumanizerOptions,
  shortenThresholdWidth: number,
): string => {
  const fullHumanizedDuration = humanizeDuration(durationMS, options)
  if (fullHumanizedDuration.length * CHAR_WIDTH < shortenThresholdWidth) {
    return fullHumanizedDuration
  }

  // Round to two units (i.e., days and hours, or hours and minutes)
  // No space between number and unit (i.e., 0h 0m)
  return shortEnglishHumanizer(durationMS, {
    largest: 2,
    round: true,
    delimiter: ' ',
  })
}

/**
 *
 * @param durationMS duration to humanize in milliseconds
 * @returns humanised time in “X h XX min” format rounded
 */
export const humanizeDurationHoursMinutes = (durationMS: number) => {
  return shortEnglishHumanizer(durationMS, {
    largest: 2,
    round: true,
    delimiter: ' ',
    units: ['h', 'm'],
  })
}
